import { render, staticRenderFns } from "./chuan.vue?vue&type=template&id=3a666560&scoped=true&"
import script from "./chuan.vue?vue&type=script&lang=js&"
export * from "./chuan.vue?vue&type=script&lang=js&"
import style0 from "./chuan.vue?vue&type=style&index=0&id=3a666560&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a666560",
  null
  
)

export default component.exports