
<template>
  <div class="mapContainerWrap">
    <div id="container" ></div>
  </div>
</template>
<style lang="scss" scoped>
#container{ margin:0;width:100%;height:713px;position:relative;background: transparent !important}
</style>
<script>
import {storeLevel,storeList} from "@/api/yaojian";
import imgSrc from "@/assets/yun/map/i5.gif";
import town from "@/assets/data/town.json"
let tigerMap=null,stationSupervisionyDistrict=null,stationSupervisionyPolygons=[],infoWindowTiger=null
export default {
  name: 'VueAmap',
  data(){
    return {
      markers:[],
      town:town,
      level:""
    }
  },
  created() {
    },
  mounted() {
    this.initDrawBoundsByCity()
    this.infoWindowShi()
  },
  methods:{
    addTown(){
      // 创建纯文本标记
      let town=this.town
      town.forEach(x=>{
        var text = new AMap.Text({
          zIndex:11,
          text:x.name,
          anchor:'center', // 设置文本标记锚点
          draggable:false,
          cursor:'pointer',
          angle:0,
          style:{
            'background-color': 'transparent',
            'border-width': 0,
            'text-align': 'center',
            'font-size': '15px',
            'color': '#fff',
          },
          position:x.position
        });

        text.setMap(tigerMap);
      })

    },
    setSmartShop(data){
      this.initCity()
      if(this.level=="smart"){return}
      this.level="smart"
      this.clearAmap()
       data.forEach(x=>{
         this.addMarker(x)
       })
    },
    initCity(){
      infoWindowTiger.close()
      let city=this.$city.find(item => item.code ==this.$store.state.cityId);
      tigerMap?.setCenter([city.longitude,city.latitude])
      tigerMap?.setZoom(city.zoom)
    },
    addStore(level){
      this.initCity()
      if(this.level==level){return}
      this.level=level
      if(level){this.clearAmap()}
      let arr=this.storeLevel
      if(level){
        arr=arr.filter(x=>{
          return x.level==level
        })
      }
      for(let i in arr){
        this.addMarker(arr[i])
      }
    },
    getStoreLevel() {
      Promise.all([storeLevel({page:1,count:1004}),storeList()]).then(res => {
        let storeList=res[1].data
        this.storeLevel=res[0].data.list
       this.storeLevel.forEach(x=>{
        let arr=storeList.filter(y=>{
            return y.name==x.storeName
         })
        if(arr.length>0){
          x.lng=arr[0].lng
          x.lat=arr[0].lat
        }

       })
       this.addStore()
      })
    },

    // 根据传入城市名称进行行政区域边界回执
    initDrawBoundsByCity() {//start

      var opts = {
        subdistrict: 0,
        extensions: 'all',
        level: 'city',
      };
      //利用行政区查询获取边界构建mask路径
      //也可以直接通过经纬度构建mask路径
      var district = new AMap.DistrictSearch(opts);
      let that=this
      let city=this.$city.find(item => item.code ==this.$store.state.cityId);
/*       city={
        code:"330000",
        name:"浙江省",
        longitude:"119.656028",
        latitude:"29.089564",
    },*/
      district.search(city.name, function(status, result) {
        var bounds = result.districtList[0].boundaries;
        var mask = []
        for(var i =0;i<bounds.length;i+=1){
          mask.push([bounds[i]])
        }
        let zoom=city.zoom
        tigerMap = new AMap.Map('container', {
           mask:mask,
          center:[city.longitude,city.latitude],
          disableSocket:true,
          viewMode:'3D',
          showLabel: true, //不显示地图文字标记
          labelzIndex:130,
          pitch:40,
          zoom:zoom,
          layers:[
              /*  new AMap.TileLayer.RoadNet({//路网
                 // rejectMapMask:true
                }),*/
            new AMap.TileLayer.Satellite()
          ]

        });

        AMap.plugin([
          'AMap.ControlBar',
        ], function(){
          // 添加 3D 罗盘控制
          tigerMap.addControl(new AMap.ControlBar({
            position: {
              right: '40px',
              bottom: '-150px'
            }
          }));
        });

         //加标注图---------------start
        that.getStoreLevel()
        if(localStorage.id=="330726"){
          that.addTown()
        }
        //加标注图---------------end

        //添加高度面
        var object3Dlayer = new AMap.Object3DLayer({zIndex:1});
        tigerMap.add(object3Dlayer)
        var height = -8000;
        var color = '#ab9b6f';//rgba
        var wall = new AMap.Object3D.Wall({
          path:bounds,
          height:height,
          color:color
        });
        wall.transparent = true
        object3Dlayer.add(wall)
        //添加描边
       /* for(var i =0;i<bounds.length;i+=1){
          new AMap.Polyline({
            path:bounds[i],
            strokeColor:'#08ffff',
            strokeWeight:4,
            map:tigerMap
          })
        }*/
      });
    },//end
    //添加marker标记
    addMarker(o) {
      if(!o.lng){return}
      let lnglat=this.toAMap(o.lng, o.lat)//百度坐标转高德
      o.lng=lnglat.lng
      o.lat=lnglat.lat
      window.sendMessage = (id,name) => {
        // 使用_this可以继续调用vue中的属性和方法，也可以完成绑定事件
        this.$router.push(`/yunStore?id=${id}&name=${name}&url=/yunHome`)
      }
      let img="",zIndex=12
     let icoSize=[]
      switch(o.level) {
        case "A":
          img="http://pic.yaojian.xusos.com/yaojian/file/2022-06-22/2022_6_22_284908700.png"
          icoSize=[10,12]
          break;
        case "B":
          img="http://pic.yaojian.xusos.com/yaojian/file/2022-06-22/2022_6_22_49414908.png"
          icoSize=[10,12]
          break;
        case "C":
          img="http://pic.yaojian.xusos.com/yaojian/file/2022-06-22/2022_6_22_1645281593.png"
          icoSize=[10,12]
          break;
        case "D":
          zIndex=13
          icoSize=[30,30]
          img=imgSrc
          break;
        case "smart":
          icoSize=[10,12]
          img="http://pic.yaojian.xusos.com/yaojian/file/2024-04-10/2024_4_10_600911108.png"
          break;
        default:
          icoSize=[10,12]
          img="http://pic.yaojian.xusos.com/yaojian/file/2022-06-22/2022_6_22_284908700.png"
      }
      let marker= new AMap.Marker({
        zIndex:zIndex,
        position:[o.lng,o.lat],
        icon: new AMap.Icon({
          image: img,
          size: new AMap.Size(icoSize[0], icoSize[1]),  //图标所处区域大小
          imageSize: new AMap.Size(icoSize[0], icoSize[1]) //图标大小
        }),
        map:tigerMap
      })
      this.markers.push(marker)
      //鼠标点击marker弹出自定义的信息窗体
      AMap.event.addListener(marker, 'click', function () {
        infoWindowTiger.setContent(`<div class='infoWin' onclick="sendMessage('${o.storeId}','${o.storeName}')">${o.storeName}</div>`);
        infoWindowTiger.open(tigerMap, marker.getPosition());
        tigerMap.setCenter([o.lng,o.lat])
        tigerMap.setZoom(20)
      });

    },

    infoWindowShi(){
      // infowidnow 的 innerHTML
      var infoWindowContent =
          '<div class="infoTitle">' +
          '</div>';

      // 创建一个自定义内容的 infowindow 实例
      infoWindowTiger = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -35),
        content: infoWindowContent
      });

    },
    clearAmap(){
      //tigerMap.clearMap();
     // console.log(this.markers)
      tigerMap?.remove(this.markers)
    },
    toAMap(lng, lat) {
  const X_PI = Math.PI * 3000.0 / 180.0;
  let x = lng - 0.0065;
  let y = lat - 0.006;
  let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  let amap_lng = z * Math.cos(theta);
  let amap_lat = z * Math.sin(theta);
  return {
    lng: amap_lng,
    lat: amap_lat
  }
},
  }

}

</script>

<style scoped>
#container >>>.amap-info-content{border-radius: 10px}
#container >>>.infoTitle{padding: 10px}
#container >>>.amap-info-close{right: 1px!important;}
#container >>>.infoWin{padding: 10px;color: #111f3e;cursor: pointer}

#container >>>.amap-logo {
  display: none;
  opacity: 0 !important;
}
#container >>>.amap-copyright {
  opacity: 0;
}
</style>

