import request from '@/utils/request'

let region=localStorage.id
if(region=="undefined"||!region){region="330726"}
/** 疫情管控药品销售情况 */
export function epidemicData(param) {
  return request({ url: '/screen/epidemic/sale', params: param, method: 'get' })
}

/** 特殊药品销售情况 */
export function specialData(param) {
  return request({ url: '/screen/special/sale', params: param, method: 'get' })
}

/** 温湿度设备状态 */
export function deviceData(param) {
  return request({ url: '/screen/device/status', params: param, method: 'get' })
}

/** 药师在岗 */
export function pharmacistData(param) {
  return request({ url: '/screen/pharmacist/status', params: param, method: 'get' })
}

/** 证件信息 */
export function paperworkData(param) {
  return request({ url: '/screen/paperwork/info', params: param, method: 'get' })
}

/** 预警信息 */
export function messageData(param) {
  return request({ url: '/screen/message/info', params: param, method: 'get' })
}

export function storeList(param) {
  return request({ url: '/screen/store/list', params: param, method: 'get' })
}

export function disposalList() {
  return request({ url: '/screen/disposal/list', method: 'get' })
}

export function listByDepart(param) {
  return request({ url: '/screen/depart', params: param, method: 'get' })
}

export function listByStoreId(param) {
  return request({ url: '/screen/list/store', params: param, method: 'get' })
}

export function storeNotice(param) {
  return request({ url: `/drug/store/notice`, params: param, method: 'get' })
}

