<template>
<div style="position: relative;">
  <div :style="fullscreen?``:`height:${height}px;z-index: 1`" :class="{fullscreen:fullscreen,mapBlock:!fullscreen}">
<!--    <div v-if="!fullscreen" style="width: 30px" class="abs hand btnFull" @click="full(true,14)"><img src="../../assets/smart/home/full.png" width="100%"/></div>
    <div v-if="fullscreen" style="width: 60px" class="abs hand btnFull" @click="full(false,13)"><img src="../../assets/smart/home/full2.png" width="100%"/></div>-->
    <div class="search flex">
      <div class="searchBar">
        <!-- <el-autocomplete
            class="skey"
            v-model="keyword"
            :fetch-suggestions="querySearch"
            @select="handleSelect"
            placeholder="请输入企业名称"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-autocomplete> -->
        <el-select
          v-model="keyword"
          v-loadmore="handleScroll"
          filterable
          remote
          clearable
          :remote-method="remoteMethod"
          :loading="loading"
          popper-class="skey"
          style="width: 350px;"
          @change="handleSelect"
        >
          <el-option
            v-for="item in restaurants"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <div class="baidumap" :style="`height:100%`">
      <div class="abs" :class="{dazi:fullscreen,xiao:!fullscreen}" style="left: 170px;z-index: 100;color: white;top:90px;line-height: 150%">
<!--        <div v-if="type==1">-->
<!--          <div class="flex_vc hand" @click="$refs.childDialog.openMessage()"><img :src="ico[0]" width="15"/>预警通知</div>-->
<!--          <div class="flex_vc hand" @click="$refs.childDialog.openTan(0)"><img :src="ico[1]" width="15"/>自主整改</div>-->
<!--          <div class="flex_vc hand" @click="$refs.childDialog.openZlzg(10)"><img :src="ico[2]" width="15"/>责令整改</div>-->
<!--          <div class="flex_vc hand" @click="$refs.childDialog.openZlzg(11)"><img :src="ico[3]" width="15"/>现场检查</div>-->
<!--          <div class="flex_vc hand" @click="$refs.childDialog.openTan(3)"><img :src="ico[4]" width="15"/>联动处置</div>-->
<!--          <div class="flex_vc hand" @click="$refs.childDialog.openTan(2,0)"><img :src="ico[5]" width="15"/>未处置</div>-->
<!--        </div>-->
        <div class="legend dituIco">
          <div class="legend-title">
            一店一档
          </div>
            <div class="legend_item hand" @click="$refs.children.addStore('A')" style="margin-top:5px">
              <div class="legend_item1">
                <img
                    src="../../assets/yun/map/i1.png"
                    border="0"
                />
              </div>
              <div class="legend_item2">A级药店</div>
              <div class="legend_item3">{{arrA}}</div>
            </div>
            <div class="legend_item hand" @click="$refs.children.addStore('B')">
              <div class="legend_item1">
                <img
                    src="../../assets/yun/map/i2.png"

                    border="0"
                />
              </div>
              <div class="legend_item2">B级药店</div>
              <div class="legend_item3">{{arrB}}</div>
            </div>
            <div class="legend_item hand" @click="$refs.children.addStore('C')">
              <div class="legend_item1">
                <img
                    src="../../assets/yun/map/i3.png"

                    border="0"
                />
              </div>
              <div class="legend_item2">C级药店</div>
              <div class="legend_item3">{{arrC}}</div>
            </div>
            <div class="legend_item hand" @click="$refs.children.addStore('D')">
              <div class="legend_item1">
                <img
                    src="../../assets/yun/map/i4.png"
                    border="0"
                />
              </div>
              <div class="legend_item2">D级药店</div>
              <div class="legend_item3">{{arrD}}</div>
            </div>
            <div class="legend_item hand" @click="$refs.children.setSmartShop(smartData)" style="margin-bottom:10px">
              <div class="legend_item1">
                <img
                    src="../../assets/yun/map/i5.png"
                    border="0"
                />
              </div>
              <div class="legend_item2">智慧药房</div>
              <div class="legend_item3">{{smartNum}}</div>
            </div>
        </div>
      </div>
      <div v-if="locId!=$store.state.cityId"  style="color: #FFFFFF;border-radius: 4px;width: 100px;cursor: pointer;z-index: 10" @click="back()">返回</div>
      <gd-map v-if="$store.state.cityId!='330700'" ref="children"/>
     <div style="position: absolute;top:50px">
       <city-map @changeCity="changeCity"  v-if="$store.state.cityId=='330700'" :dialog-data="dialogCity"></city-map>
     </div>
    </div>
  </div>
  <chuan ref="childDialog"/>
<!--  <div class="waves" ></div>-->
</div>
</template>
<style lang="less">
.dituIco{
  img{height: 14px;}
}
.legend-title{
  overflow: hidden;
  height: 40px;
  background: rgba(8, 18, 57, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  font-weight: 700;
}
.legend {
  right: -7px;
  top: -36px;
  position: absolute;
  width: 167px;
  height: 191px;
  background: rgba(0, 53, 103, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  .legend_item {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .legend_item1 {
    margin-left: 10px;


    border-radius: 2px;
  }
  .legend_item2 {
    margin-left: 5px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
  .legend_item3 {
    margin-left: 10px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffc038;
  }
}
.hand{cursor: pointer}
</style>
<script>
// import BdMap from '../yunBaiduMap'
import cityMap from "@/views/components/cityMap";
import GdMap from '../yunGaode'
import chuan from "@/views/components/chuan";
import {storeList} from "@/api/screen";
import { storeLevel, storeListPage, groupStoreLevelApi, pageByOptionsApi } from "@/api/yaojian";
export default {
  name: "baidumap",
  components: { chuan,GdMap,cityMap},
  props: {
    height: {
      type: Number,
      default: 470
    },
    type: {
      type: Number,
      default: 1
    },
    dialogData: {
      type: Array,
      default: () => []
    },
    dialogCity: {
      type: Object,
      default: () => {}
    },
    dialogArr: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      smartNum:0,
      smartData:[],
      locId:localStorage.id,
      ico:[
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1828051103.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_359700814.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_350535907.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_943563961.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1363424593.png",
          "http://pic.yaojian.xusos.com/yaojian/file/2022-05-31/2022_5_31_1910063012.png",
      ],
      fullscreen:false,
      keyword:"",
      restaurants:[],
      region:this.$id,
      arrA:0,
      arrB:0,
      arrC:0,
      arrD:0,
      cityData: [],
      page: 1,
      pageSize: 100,
      moreLoading: false,
      searchKeyword: '',
      loading: false,
      totalPage: 0,
    }
  },
  created() {
    this.page = 1
    this.handleScroll()
    // this.loadAll()//获取店铺
    // if (this.dialogData) {
    //   this.initStoreLevelData()
    // } else {
      this.loadStoreLevel()
    // }
    this.getSmartShopList()
  },
  mounted() {
    //this.wave()
    
  },
  methods:{
   async getSmartShopList(){
     let data={
       page:1,
       count:500,
       regionCode:this.$store.state.cityId,
       type:0,
       smartPharmacy:2,
       isMain:0
     }
      let rs=await storeListPage(data)
     if(rs.code==200){
      this.smartNum=rs.data.total
       this.smartData=rs.data.list
       this.smartData.map(x=>{
         x.level='smart'
         x.storeName=x.name
       })
       this.$refs.children?.setSmartShop(this.smartData)
     }
    },
    back(){
      this.$store.commit('SET_cityId',localStorage.id)
      this.loadStoreLevelData()
      this.getSmartShopList()
      this.$emit("changeCity",{id:localStorage.id})
    },
    changeCity(e){
      this.loadStoreLevelData()
      this.getSmartShopList()
      this.$emit("changeCity",{id:e.id})
    },
    initStoreLevelData() {
      const levelData = this.dialogData
      // console.log('levelData', levelData)
      this.arrA=levelData.filter(x=>{return x.level=="A"})
      this.arrB=levelData.filter(x=>{return x.level=="B"})
      this.arrC=levelData.filter(x=>{return x.level=="C"})
      this.arrD=levelData.filter(x=>{return x.level=="D"})
    },
   async loadStoreLevel(){//标注等级店铺
      const _this = this
      Object.keys(this.dialogArr).forEach(key => {
        if (key === 'A') {
          _this.arrA = _this.dialogArr['A'].cnt | 0
        } else if (key === 'B') {
          _this.arrB = _this.dialogArr['B'].cnt | 0
        } else if (key === 'C') {   
          _this.arrC = _this.dialogArr['C'].cnt | 0
        } else if (key === 'D') {
          _this.arrD = _this.dialogArr['D'].cnt | 0
        }
      })
    //  let res=await storeLevel(post)
    //  if(res.code==200){
    //    this.arrA=res.data.list.filter(x=>{return x.level=="A"})
    //    this.arrB=res.data.list.filter(x=>{return x.level=="B"})
    //    this.arrC=res.data.list.filter(x=>{return x.level=="C"})
    //    this.arrD=res.data.list.filter(x=>{return x.level=="D"})
       
    //  }
    },
    loadStoreLevelData() {
      const _this = this
      _this.arrA = 0
      _this.arrB = 0
      _this.arrC = 0
      _this.arrD = 0
      groupStoreLevelApi({}).then(res => {
          Object.keys(res.data).forEach(key => {
              if (key === 'A') {
                  _this.arrA = res.data['A'].cnt | 0
              } else if (key === 'B') {
                  _this.arrB = res.data['B'].cnt | 0
              } else if (key === 'C') {   
                  _this.arrC = res.data['C'].cnt | 0
              } else if (key === 'D') {
                  _this.arrD = res.data['D'].cnt | 0
              }
          })
      })
    },
    full(b,num){
      this.fullscreen=b
      this.$refs.children.zoom({lng:119.92528,lat:29.454017},num)
    },
    //搜素代码----------------------------------------start
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=-1);
      };
    },
   async loadAll() {
      let arr
      let that=this
      let rs=await storeList({lai:"gaode"})
     arr=rs.data
     for(let i in arr){
       arr[i].value=arr[i].name
     }
     that.restaurants=arr
/*      axios.get('/screen/store/list?a=baidu&region='+this.region, {})
          .then(function (response) {
            arr=response.data.data
            for(let i in arr){
              arr[i].value=arr[i].name
            }
            that.restaurants=arr
          })*/

    },
    handleSelect(id) {
      const item = this.restaurants.find(x => x.id === id)
      console.log('handleSelect', item)
      let url=this.$route.path
      this.$router.push(`/yunStore?id=${item.id}&name=${item.name}&new=1&url=${url}`)
      //this.$refs.children.handleSelect(item)
    },
    //搜素代码----------------------------------------end
    async remoteMethod(queryString) {
      // if (queryString.trim() === '') {
      //   return false
      // }
      this.page = 1
      // this.restaurants = []
      this.searchKeyword = queryString
      const { data } = await pageByOptionsApi({
        page: this.page,
        count: this.pageSize,
        name: this.searchKeyword,
        sortLive: 1
      })
      this.restaurants = data.list
      this.totalPage = data.totalPage
      this.$nextTick(() => {
        const el = document.querySelector('.xs-select .el-select-dropdown__wrap')
        if (el) {
          el.scrollTop = 0
        }
      })
    },
    handleScroll() {
      if (this.moreLoading) {
        return
      }
      if (this.page >= this.totalPage && this.totalPage !== 0) {
        return
      }
      this.moreLoading = true
      pageByOptionsApi({
        page: this.page,
        count: this.pageSize,
        name: this.searchKeyword,
        sortLive: 1
      }).then(res => {
        this.page += 1
        this.moreLoading = false
        this.totalPage = res.data.totalPage
        this.restaurants = this.restaurants.concat(res.data.list)
      })
    }
  }
}
</script>

<style scoped>
.waves {
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  z-index: 0;
}
.dazi{font-size: 20px;line-height: 31px}
.dazi img{height: 14px}
.xiao{font-size: 12px}
.btnFull{
  z-index: 101;
  bottom: 10px;
  right: 10px;
}
.hand{cursor: pointer}
.flex{display: flex}
.flex_vc{display: flex;align-items: center}
.mapBlock{position: relative;width: 100%;transition: all 0.1s ease-out}
.fullscreen{position: absolute;top:0;left:0;width: 1920px;height: 1080px;z-index: 99;transition: all 0.1s ease-out}
.search{position: absolute;  left: calc(50% - 175px);top: 10px;z-index: 101}
.map2{
  height: 464px;
  width: 646px;
  position: absolute;
  z-index: 0;

}
.searchBar{margin-left: 20px;margin-top: 5px}
>>>.el-input__icon{line-height: 30px}
>>>.el-input__inner{
  border: 1px solid #17406E;
  background-color: #111F3E;
  border-radius: 25px;
  width: 351px;
  color: #28C0EA;
  font-size: 16px;
  opacity: 0.7;
  height: 30px;
  line-height: 30px;
}
.skey /deep/ .el-scrollbar__wrap {
  border: 1px solid #17406E;
  background-color: #111F3E;
  color: #28C0EA;
}
.abs{
  position: absolute;
}
</style>
