<template>
  <div>
    <div class="yunHeader" v-if="currentPage.level==1">
      <div style="position: absolute;top:35px;right: 100px;font-size: 22px">{{nowTime}} <span style="color: #00aefe" @click="logout">退出</span></div>
    </div>
    <div class="yunHeader2 drug-control-header" v-if="currentPage.level>1">
      <div style="color: #00D3F9;position: absolute;width: 35px;text-align: center;left: 943px;top: 61px;font-family: 微软雅黑;font-size: 25px">
        <span v-if="currentPage.level==2">Ⅱ</span>
        <span v-if="currentPage.level==3">Ⅲ</span>
      </div>
      <div class="drug-control-header-back" @click="back()">
        <img src="../assets/smart/home/dialog2.png" alt="" />
        <span>返回</span>
      </div>
      <div class="drug-control-header-home" @click="go('/')">
        <img src="../assets/yun/home/home.png" alt="" />
        <span>返回驾驶舱</span>
      </div>
      <div style="padding-top: 29px"><span class="drug-control-header-title" >{{currentPage.name}}</span></div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
export default {
  name: "yunHeader",
  data(){
    return {
      currentPage:{},
      nowTime:moment().format('YYYY年MM月DD日 HH:mm:ss'),
      path:this.$route.path,
      navArr:[
        {name:"首页",level:1,parent:"",path:"/"},
        {name:"首页",level:1,parent:"",path:"/yunHome"},
        {name:"便捷买药",level:2,parent:"首页",path:"/yunMedicine"},
        {name:"用药咨询",level:2,parent:"首页",path:"/yunUse"},
        {name:"药事服务",level:2,parent:"首页",path:"/yunYaoshi"},
        {name:"预警分析",level:2,parent:"首页",path:"/yunWarning"},
        {name:"考核评级",level:2,parent:"首页",path:"/yunAssess"},
        {name:"精密智管",level:2,parent:"首页",path:"/yunRegulation"},
        {name:"三级买药",level:2,parent:"首页",path:"/yunSanji"},
        {name:"处置分析",level:3,parent:"精密智管",path:"/yunRisk"},
        {name:"进销存动态分析",level:3,parent:"精密智管",path:"/yunStockSell"},
        {name:"经营资质及设备",level:3,parent:"精密智管",path:"/yunEnterprise"},
        {name:"药店防疫管理",level:3,parent:"精密智管",path:"/yunEpidemic"},
        {name:"药品管控",level:3,parent:"精密智管",path:"/yunDrugControl"},
      ],
  }},
  created() {
    let arr=this.navArr.filter(x=>{
      return x.path==this.path
    })
    this.currentPage=arr[0]
     setInterval(()=>{
       this.nowTime=moment().format('YYYY年MM月DD日 HH:mm:ss')
     },1000)
    },
  methods:{
    logout(){
      this.$Cookies.remove("AuthorizationToken")
      this.$Cookies.remove("AuthorizationToken",{domain:'yaojian.xusos.com'})
      this.$router.replace("/login")
    },
    back(){
      if(this.currentPage.level==2){
        this.$router.push("/")
      }else{
        this.$router.back()
      }

    },
    go(url){
      this.$router.push(url)
    }
  }
}
</script>

<style scoped>
.hand{cursor: pointer;color:#00d7fe
}
.menu_left {padding-top: 15px}
.menu_left .itemC{
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 62px;
}
.menu_left{padding-left: 110px;position: absolute;}
.menu_left .item{
  cursor: pointer;
  background-image: url("../assets/yun/home/m1.png");
  position: absolute;
  width: 180px;
  height: 62px;
}
.menu_left .item:hover{
  background-image: url("../assets/yun/home/m2.png");
  position: absolute;
  width: 180px;
  height: 62px;
}
.menu_left .active{
  background-image: url("../assets/yun/home/m2.png");
  position: absolute;
  width: 180px;
  height: 62px;
}

.menu_right {padding-top: 15px}
.menu_right .itemC{
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 62px;
}
.menu_right{right: 657px;position: absolute}
.menu_right .item{
  cursor: pointer;
  background-image: url("../assets/yun/home/m3.png");
  position: absolute;
  width: 180px;
  height: 62px;
}
.menu_right .active{
  background-image: url("../assets/yun/home/m4.png");
  position: absolute;
  width: 180px;
  height: 62px;
}
.menu_right .item:hover{
  background-image: url("../assets/yun/home/m4.png");
  position: absolute;
  width: 180px;
  height: 62px;
}
.yunHeader{
  background-image: url("../assets/yun/home/headernew.png");
  height: 91px;
  width: 1920px;
  text-align: center;

  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  position: relative;
}
.yunHeader2{
  background-image: url("../assets/yun/home/header2.png");
  height: 91px;
  width: 1920px;
  text-align: center;

  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  position: relative;
}
.pageTitle{
  line-height:91px;
  font-size: 50px;
}
</style>
<style lang="less">
.drug-control-header{
  .drug-control-header-back {
    cursor: pointer;
    position: absolute;
    left: 46px;
    top: 60%;
    transform: translateY(-50%);
    span {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #00d7fe;
      line-height: 26px;
      margin: 0 30px 0 10px;
    }
    img {
      width: 23px;
      height: 19px;
    }
  }
  .drug-control-header-home {
    cursor: pointer;
    position: absolute;
    left: 150px;
    top: 60%;
    transform: translateY(-50%);
    span {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #00d7fe;
      line-height: 26px;
      margin: 0 30px 0 10px;
    }
    img {
      width: 23px;
      height: 19px;
    }
  }
  .drug-control-header-title {
    line-height: 38px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 26px;
    background: linear-gradient(to bottom, #fff,#00d2e9);
    -webkit-background-clip: text; color: transparent;
  }
}

</style>
