<template>
    <div class="dialog">
        <!--  整改处置-->
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="1600px"
            top="5vh"
            @close="closeYjcz"
            :destroy-on-close="true"
        >
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogVisible = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    {{ ["预警处置", "自主整改", "部门处置", "联动处置"][tanType] }}
                </div>
                <div class="biao">
                    <div class="yjcz" v-if="tanType == 0">
                        <div class="flex">
                            <div
                                :class="{ zgbtn1: yjczBtn == 1, ztbtn2: yjczBtn != 1 }"
                                class="hand"
                                @click="changeYjcz(1)"
                            >
                                自主整改({{ zzzgData.total }})
                            </div>
                            <div
                                :class="{ zgbtn1: yjczBtn == 2, ztbtn2: yjczBtn != 2 }"
                                class="hand"
                                @click="changeYjcz(2)"
                            >
                                部门处置({{ bmczCount1 + bmczCount2 }})
                            </div>
                            <div
                                :class="{ zgbtn1: yjczBtn == 3, ztbtn2: yjczBtn != 3 }"
                                class="hand"
                                @click="changeYjcz(3)"
                            >
                                联动处置(2)
                            </div>
                        </div>
                    </div>
                    <div id="zzzg" v-if="tanType == 1 || (yjczBtn == 1 && tanType == 0)">
                        <div class="flex" v-if="tanType == 1">
                            <div
                                :class="{ zgbtn1: zgzt == 1, ztbtn2: zgzt == 2 }"
                                class="hand"
                                @click="search(1)"
                            >
                                已整改({{ yzgData.total }})
                            </div>
                            <div
                                :class="{ zgbtn1: zgzt == 2, ztbtn2: zgzt == 1 }"
                                class="hand"
                                @click="search(2)"
                            >
                                未整改({{ wzgData.total }})
                            </div>
                        </div>
                        <table class="listtable">
                            <tr class="listTitle">
                                <td style="width: 220px">企业名称</td>
                                <td>标题</td>
                                <td>整改内容</td>
                                <td style="width: 130px">通知时间</td>
                                <td>处理人</td>
                                <td>提交内容</td>
                                <td>图片</td>
                                <td style="width: 130px">处理时间</td>
                                <td>状态</td>
                                <td style="width: 100px">审核人</td>
                                <td style="width: 130px">审核时间</td>
                                <!--              <td>操作</td>-->
                            </tr>
                            <tr class="listData dabiaozi" v-for="x in zzzgData.list">
                                <td ref="td1" :class="{ tdView: x.isView }">
                                    <div class="boxcontent" v-if="x.isView">33</div>
                                    <div>{{ x.storeName }}</div>
                                </td>
                                <td style="height: 108px">{{ x.title }}</td>
                                <td>
                                    <div style="width: 150px" class="overflow" :title="x.content">
                                        {{ x.content }}
                                    </div>
                                </td>
                                <td>
                                    <div class="time">{{ x.createDate }}</div>
                                </td>
                                <td>{{ x.handleName ? x.handleName.split(":")[1] : "" }}</td>
                                <td>
                                    <div
                                        style="margin: 0 auto;width: 100px"
                                        class="overflow"
                                        :title="x.repayContent"
                                    >
                                        {{ x.repayContent }}
                                    </div>
                                </td>
                                <td>
                                    <el-image
                                        style="width: 50px; height:50px"
                                        :src="
                                            `${
                                                x.repayPic ? x.repayPic[0] : ''
                                            }?x-oss-process=image/resize,m_fill,h_100,w_100`
                                        "
                                        :preview-src-list="x.repayPic"
                                    >
                                        <div slot="placeholder" style="line-height: 50px">
                                            加载中
                                        </div>
                                    </el-image>
                                </td>
                                <td>
                                    <div class="time">{{ x.handleTime }}</div>
                                </td>
                                <td>
                                    <span v-if="x.status === 10" type="info">待整改</span>
                                    <span v-if="x.status === 20" type="warning">整改中</span>
                                    <span v-if="x.status === 30" type="primary">已整改</span>
                                    <span v-if="x.status === 40" type="success">审核完成</span>
                                </td>
                                <td>{{ x.completeName }}</td>
                                <td>
                                    <div class="time">{{ x.completeTime }}</div>
                                </td>
                                <!--                <td>-->
                                <!--                  <div @click="shou(x)" class="btnDetail" v-if="!x.isView">查看详情</div>-->
                                <!--                  <div @click="shou(x)" class="btnDetail2" v-if="x.isView">收起详情</div>-->
                                <!--                </td>-->
                            </tr>
                        </table>
                    </div>
                    <div id="bmjc" v-if="tanType == 2 || yjczBtn == 2">
                        <div class="flex" v-if="tanType == 2">
                            <div
                                :class="{ zgbtn1: bmczZt == 1, ztbtn2: bmczZt == 0 }"
                                class="hand"
                                @click="search2(1)"
                            >
                                已处置({{ bmczCount1 + bmczCount2 }})
                            </div>
                            <div
                                :class="{ zgbtn1: bmczZt == 0, ztbtn2: bmczZt == 1 }"
                                class="hand"
                                @click="search2(0)"
                            >
                                未处置(0)
                            </div>
                        </div>
                        <table class="listtable" id="bmcz">
                            <tr class="listTitle">
                                <td>预警编号</td>
                                <td>预警企业名称</td>
                                <td class="tx-c" style="width: 130px">企业负责人</td>
                                <td>电话</td>
                                <td>执法类型</td>
                                <td>状态</td>
                                <td class="tx-c" style="width: 130px">处置部门</td>
                                <td>处置结果</td>
                                <td>备注说明</td>
                                <td style="width: 103px">创建时间</td>
                            </tr>
                            <tr class="listData dabiaozi" v-for="x in bmczData.list">
                                <td style="height: 112px" ref="td1" :class="{ tdView: x.isView }">
                                    <!--        <div class="boxcontent" v-if="x.isView">33</div>-->
                                    <div>{{ x.code }}</div>
                                </td>
                                <td>{{ x.storeName }}</td>
                                <td class="tx-c">{{ x.contract }}</td>
                                <td>{{ mbl(x.phone) }}</td>
                                <td>{{ zflx(x.type) }}</td>
                                <td>
                                    <div class="tag">
                                        <el-tag type="success">{{ status(30) }}</el-tag>
                                    </div>
                                </td>
                                <td class="tx-c">{{ x.regulatoryName }}</td>
                                <td><span>因系统原因未上传处方，建议不予扣分</span></td>
                                <td>{{ x.remark }}</td>
                                <td>
                                    <div class="time">{{ x.createDate }}</div>
                                </td>
                            </tr>
                        </table>

                        <div
                            style="color: white;line-height: 200px;text-align: center"
                            v-if="bmczZt == 0"
                        >
                            暂无数据
                        </div>
                    </div>

                    <table
                        class="listtable"
                        id="ldcz"
                        v-if="tanType == 3 || (yjczBtn == 3 && tanType == 0)"
                    >
                        <tr class="listTitle">
                            <td style="width:258px">药店名称</td>
                            <td>姓名</td>
                            <td>身份证</td>
                            <td>手机号码</td>
                            <td style="width: 70px" class="tx-c">体温</td>
                            <td class="tx-c">健康码</td>
                            <td>行程记录</td>
                            <td class="tx-c">销售记录</td>
                            <td>上报疫情指挥部</td>
                            <td style="width:130px" class="tx-c">时间</td>
                            <td style="width:130px" class="tx-c">处置反馈</td>
                            <td class="tx-c">操作</td>
                        </tr>
                        <tr
                            v-for="x in ldczData"
                            class="dabiaozi"
                            :class="{ listData: !x.isView, listData2: x.isView }"
                        >
                            <td>
                                <div style="position: relative" :class="{ chakan: x.isView }">
                                    <div class="tdContent" style="text-align: left">{{ x.a1 }}</div>
                                    <div class="viewContent" v-if="x.isView">
                                        <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr class="listTitle2" style="height: 35px">
                                                <td>反馈时间</td>
                                                <td>反馈单位</td>
                                                <td>联系人</td>
                                                <td>电话</td>
                                                <td>内容</td>
                                            </tr>
                                            <tr class="biao2" v-if="x.a2 == '楼润高'">
                                                <td>2022-4-19 20:15:47</td>
                                                <td>县疫情指挥部</td>
                                                <td>吴望</td>
                                                <td>{{ mbl("18266921576") }}</td>
                                                <td>已指令浦阳街道片管员跟踪处理</td>
                                            </tr>
                                            <tr class="biao2" v-if="x.a2 == '朱华兴'">
                                                <td>2022-4-23 19:10:45</td>
                                                <td>县疫情指挥部</td>
                                                <td>吴望</td>
                                                <td>{{ mbl("18266921576") }}</td>
                                                <td>已指令浦阳街道片管员跟踪处理</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent nowrap">
                                    {{ x.a2 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent">
                                    {{ x.a3 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent nowrap">
                                    {{ x.a4 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent shuzi">
                                    {{ x.a5 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a6 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a7 }}
                                </div>
                            </td>
                            <td class="tx-c">
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a8 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a9 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a10 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a11 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ tdBtn: x.isView }" class="tx-c">
                                    <div @click="shou(x)" class="btnDetail" v-if="!x.isView">
                                        查看详情
                                    </div>
                                    <div @click="shou(x)" class="btnDetail2" v-if="x.isView">
                                        收起详情
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <!--        自主整改翻页-->
                    <div class="pager flex" v-if="tanType == 1 || (yjczBtn == 1 && tanType == 0)">
                        <!--          <div class="page" @click="changePage('prev')"><</div>-->
                        <!--          <div style="margin-left: 10px;margin-right: 10px">{{post.page}}/{{pageCount}}</div>-->
                        <!--          <div class="page" @click="changePage('next')">  > </div>-->
                        <pager
                            :page="post.page"
                            :total="zzzgData.total"
                            :page-size="pageCount"
                            @pagination="changePage"
                        />
                    </div>

                    <!--        部门处置翻页-->
                    <div class="pager flex" v-if="tanType == 2 || (yjczBtn == 2 && tanType == 0)">
                        <pager
                            :page="post.page"
                            :total="bmczData.total"
                            :page-size="5"
                            @pagination="changePage2"
                        />
                        <!--          <div class="page" @click="changePage2('prev')"><</div>-->
                        <!--          <div style="margin-left: 10px;margin-right: 10px">{{post2.page}}/{{pageCount2}}</div>-->
                        <!--          <div class="page" @click="changePage2('next')">  > </div>-->
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--处方列表-->
        <el-dialog
            :visible.sync="dialogPrescription"
            width="1600px"
            top="5vh"
            @close="closed"
            :destroy-on-close="true"
        >
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogPrescription = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    {{ prescriptionTitle }}
                </div>
                <div class="biao">
                    <div class="flex saleSearch">
                        <div class="label">药品名称</div>
                        <div class="item">
                            <el-input v-model="postPrescription.goodsName" clearable></el-input>
                        </div>
                        <div class="label">企业名称</div>
                        <div class="item">
                            <el-select
                                style="width: 220px"
                                v-model="storeIds"
                                multiple
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in storeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="label">日期</div>
                        <div class="item">
                            <el-date-picker
                                v-model="prescriptionTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            >
                            </el-date-picker>
                        </div>
                        <div class="item flex" style="align-items: center">
                            <div @click="searchPrescription" class="btnSearch">
                                <img src="../../assets/smart/home/s.png" />
                            </div>
                        </div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>医院名称</td>
                            <td class="tx-c">医生姓名</td>
                            <td class="tx-c">患者姓名</td>
                            <td class="tx-c">性别</td>
                            <td class="tx-c">年龄</td>
                            <td>联系电话</td>
                            <td class="tx-c">审核药师</td>
                            <td style="width: 105px">销售日期</td>
                            <td class="tx-c">处方图片</td>
                            <td class="tx-c">审方图片</td>
                            <td>商品信息</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in prescriptionData.list">
                            <td style="height: 114px">{{ x.hospital }}</td>
                            <td class="tx-c">{{ x.doctor }}</td>
                            <td class="tx-c">{{ x.patientName }}</td>
                            <td class="tx-c">{{ x.patientSex == 1 ? "男" : "女" }}</td>
                            <td class="tx-c">{{ x.patientAge }}</td>
                            <td>{{ mbl(x.patientPhone) }}</td>
                            <td class="tx-c">{{ x.pharmacist }}</td>
                            <td>{{ x.createDate }}</td>
                            <td class="tx-c">
                                <el-image
                                    style="width: 50px; height:50px"
                                    :src="
                                        `${x.prescriptionImage}?x-oss-process=image/resize,m_fill,h_100,w_100`
                                    "
                                    :preview-src-list="[x.prescriptionImage]"
                                >
                                    <div slot="placeholder" style="line-height: 50px">
                                        加载中
                                    </div>
                                </el-image>
                            </td>
                            <td class="tx-c">
                                <el-image
                                    style="width: 50px; height:50px"
                                    :src="
                                        `${x.scenePhoto}?x-oss-process=image/resize,m_fill,h_100,w_100`
                                    "
                                    :preview-src-list="[x.scenePhoto]"
                                >
                                    <div slot="placeholder" style="line-height: 50px">
                                        加载中
                                    </div>
                                </el-image>
                            </td>
                            <td><span class="btn" @click="openGoods(x)">查看</span></td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postPrescription.page"
                            :total="prescriptionData.total"
                            :page-size="postPrescription.count"
                            @pagination="getPrescription"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--处方结果分析-->
        <el-dialog
            :visible.sync="dialogCzjg"
            width="1600px"
            top="5vh"
            @close="closed"
            :destroy-on-close="true"
        >
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogCzjg = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    <span>{{ czjg }}</span>
                </div>
                <div class="biao">
                    <table
                        class="listtable dazi"
                        v-if="czjg == '取消医保' || czjg == '取消好商风贷'"
                    >
                        <tr class="listTitle">
                            <td><div class="singleField">药店名称</div></td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江黄宅紫金家园店
                                </div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江东山路店
                                </div>
                            </td>
                        </tr>
                    </table>

                    <table class="listtable dazi" v-if="czjg == '暂停医保' || czjg == '暂停云药房'">
                        <tr class="listTitle">
                            <td><div class="singleField">药店名称</div></td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江黄宅钟村分店
                                </div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江七里药房
                                </div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td><div class="singleField">浦江五和大药房有限公司</div></td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江郑家坞店
                                </div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td><div class="singleField">浦江丰安药品销售有限公司</div></td>
                        </tr>
                        <tr class="listData">
                            <td><div class="singleField">金华市太和堂医药浦江岩头连锁店</div></td>
                        </tr>
                    </table>

                    <table class="listtable dazi" v-if="czjg == '信用降级'">
                        <tr class="listTitle">
                            <td><div class="singleField">药店名称</div></td>
                        </tr>
                        <tr class="listData" v-for="x in xyjjData">
                            <td style="height: 130px;">
                                <div class="singleField">{{ x.name }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager" v-if="czjg == '信用降级'">
                        <pager
                            :page="postXyjj.page"
                            :total="58"
                            :page-size="5"
                            @pagination="getXyjj"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--动态评级-->
        <el-dialog
            :visible.sync="dialogDtpj"
            width="1600px"
            top="5vh"
            @close="closed"
            :destroy-on-close="true"
        >
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogDtpj = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    <span>企业动态评级</span>
                </div>
                <div class="biao">
                    <div class="huizong">
                        <div class="btnHui flex_vc">汇总数据：{{ dtpjData.total }}</div>
                        <div class="btnHui flex_vc">占比：{{ perHui }}%</div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 50%">药店名称</td>
                            <td class="tx-c">上年度信用等级</td>
                            <td class="tx-c">当前信用得分</td>
                            <td class="tx-c">当前监管风险等级</td>
                        </tr>
                        <tr class="listData" v-for="x in dtpjData.list">
                            <td style="height: 116px">
                                <div>{{ x.storeName }}</div>
                            </td>
                            <td>
                                <div class="tx-c">{{ x.lastYearScore }}</div>
                            </td>
                            <td>
                                <div class="tx-c">{{ x.score }}</div>
                            </td>
                            <td>
                                <div class="tx-c">{{ x.level }}</div>
                            </td>
                        </tr>
                    </table>

                    <div
                        style="color: white;line-height: 300px;text-align: center"
                        v-if="dtpjData.total == 0"
                    >
                        暂无数据
                    </div>
                    <div class="pager" v-if="dtpjData.total > 0">
                        <pager
                            :page="postDtpj.page"
                            :total="dtpjData.total"
                            :page-size="postDtpj.count"
                            @pagination="getDtpj"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--药事服务响应率-->
        <el-dialog
            :visible.sync="dialogYsfwxyl"
            width="1600px"
            top="5vh"
            @close="closed"
            :destroy-on-close="true"
        >
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogYsfwxyl = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    <span>药事服务响应率</span>
                </div>
                <div class="biao">
                    <div class="huizong">
                        <div class="btnHui flex_vc" style="width:200px">签约医生数量：58</div>
                        <div class="btnHui flex_vc" style="width:200px">平局响应率：93%</div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 50px">序号</td>
                            <td>签约医生姓名</td>
                            <td>响应率</td>
                            <td>当月响应天数</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in ysfwxylData2">
                            <td>{{ i + 1 }}</td>
                            <td>{{ x.name }}</td>
                            <td>{{ x.per }}%</td>
                            <td>{{ x.num }}</td>
                        </tr>
                    </table>

                    <div
                        style="color: white;line-height: 300px;text-align: center"
                        v-if="ysfwxylData2.length == 0"
                    >
                        暂无数据
                    </div>
                    <div class="pager" v-if="ysfwxylData2.length > 0">
                        <pager
                            :page="postYsfwxyl.page"
                            :total="58"
                            :page-size="10"
                            @pagination="getYsfwxyl"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--销售管控-->
        <el-dialog
            :visible.sync="dialogControl"
            width="1600px"
            top="5vh"
            @close="closed"
            :destroy-on-close="true"
        >
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogControl = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    一键锁定
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>名称</td>
                            <td>控制力度</td>
                            <td>时间范围</td>
                            <td>添加时间</td>
                            <td>区域</td>
                            <td>操作</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in controlData.list">
                            <td style="height: 120px">{{ x.name }}</td>
                            <td>
                                <div class="tag">
                                    <el-tag v-if="x.way === 1" type="danger">禁售</el-tag>
                                    <el-tag v-if="x.way === 2" type="warning">限售</el-tag>
                                    <el-tag v-if="x.way === 3" type="success">提示</el-tag>
                                </div>
                            </td>
                            <td>
                                {{ formatTime(x.startTime, "{y}-{m}-{d}") }} 至
                                {{ formatTime(x.endTime, "{y}-{m}-{d}") }}
                            </td>
                            <td>
                                <div class="time">{{ x.createDate }}</div>
                            </td>
                            <td>{{ x.regionName }}</td>
                            <td><span class="btn" @click="openControl2(x)">查看</span></td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postControl.page"
                            :total="controlData.total"
                            :page-size="postControl.count"
                            @pagination="getControl"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogControl2"
            width="1382px"
            top="112px"
            :destroy-on-close="true"
        >
            <div class="tanWin2">
                <div class="tanTitle2">
                    <div @click="dialogControl2 = false" class="btnBack2 hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    <span>销控查看</span>
                </div>
                <div class="tanContent" style="width: 90%" v-if="contorl.length > 0">
                    <div class="flex tymBox scrbar">
                        <div class="tym" v-for="x in contorl">
                            {{ x }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="contorl2.length > 0"
                    class="scrbar"
                    style="padding-top: 26px;height: 260px;overflow: auto"
                >
                    <table id="contorl2" width="100%" cellspacing="0" cellpadding="0">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>商品名</td>
                            <td>规格</td>
                            <td class="tx-c">单位</td>
                            <td>生产企业</td>
                            <td>批号范围</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in contorl2">
                            <td>{{ i + 1 }}</td>
                            <td>{{ x.name }}</td>
                            <td>{{ x.spec }}</td>
                            <td class="tx-c">{{ x.unit }}</td>
                            <td>{{ x.company }}</td>
                            <td>
                                <div class="flex">
                                    <span class="pici" v-for="y in x.batch">{{ y }}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--  群众满意度-->
        <el-dialog
            :visible.sync="dialogSatisfaction"
            width="1382px"
            top="112px"
            @close="closed"
            :destroy-on-close="true"
        >
            <div class="tanWin2">
                <div class="tanTitle2">
                    <div @click="dialogSatisfaction = false" class="btnBack2 hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    <span v-if="tan == 'discoveryAnalysis'">三级找药分析</span>
                    <span v-if="tan == 'discoveryRate'">三级找药找到率</span>
                    <span v-if="tan == 'response'">药店响应率</span>
                    <span v-if="tan == 'ask'">问诊响应</span>
                    <span v-if="tan == 'satisfaction'">群众满意度</span>
                    <span v-if="tan == 'insurance'">医保支付</span>
                    <span v-if="tan == 'prescriptionAnalysis'">处方占比分析</span>
                    <span v-if="tan == 'znyxtx'">智能药箱提醒</span>
                    <span v-if="tan == 'znyxhyd'">智能药箱活跃度</span>
                    <span v-if="tan == 'ysfwxyl'">药事服务响应率</span>
                    <span v-if="tan == 'znyxtxfx'">智能药箱提醒分析</span>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'discoveryAnalysis'">
                    <div class="item">
                        <div class="item1">385</div>
                        <div class="item2">三公里</div>
                    </div>
                    <div class="item">
                        <div class="item1">271</div>
                        <div class="item2">县域内</div>
                    </div>
                    <div class="item">
                        <div class="item1">135</div>
                        <div class="item2">县域外</div>
                    </div>
                    <div class="item">
                        <div class="item1">15</div>
                        <div class="item2">未找到</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'discoveryRate'">
                    <div class="item">
                        <div class="item1">806</div>
                        <div class="item2">三级找药</div>
                    </div>
                    <div class="item">
                        <div class="item1">791</div>
                        <div class="item2">找到数</div>
                    </div>
                    <div class="item">
                        <div class="item1">98.3%</div>
                        <div class="item2">找到率</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'response'">
                    <div class="item">
                        <div class="item1">806</div>
                        <div class="item2">三级找药</div>
                    </div>
                    <div class="item">
                        <div class="item1">785</div>
                        <div class="item2">药店响应</div>
                    </div>
                    <div class="item">
                        <div class="item1">97.5%</div>
                        <div class="item2">药店响应率</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'ask'">
                    <div class="item">
                        <div class="item1">3586</div>
                        <div class="item2">在线问诊总量</div>
                    </div>
                    <div class="item">
                        <div class="item1">179</div>
                        <div class="item2">三大医院问诊量</div>
                    </div>
                    <div class="item">
                        <div class="item1">2691</div>
                        <div class="item2">互联网医院问诊量</div>
                    </div>
                    <div class="item">
                        <div class="item1">716</div>
                        <div class="item2">签约医生问诊量</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'satisfaction'">
                    <div class="item">
                        <div class="item1">12728</div>
                        <div class="item2">满意</div>
                    </div>
                    <div class="item">
                        <div class="item1">274</div>
                        <div class="item2">不满意</div>
                    </div>
                    <div class="item">
                        <div class="item1">684</div>
                        <div class="item2">未评价</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'insurance'">
                    <div class="item">
                        <div class="item1">37867680</div>
                        <div class="item2">自费</div>
                    </div>
                    <div class="item">
                        <div class="item1">420752</div>
                        <div class="item2">医保</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'prescriptionAnalysis'">
                    <div class="item">
                        <div class="item1">1009999</div>
                        <div class="item2">互联网医院</div>
                    </div>
                    <div class="item">
                        <div class="item1">189374</div>
                        <div class="item2">签约医生</div>
                    </div>
                    <div class="item">
                        <div class="item1">37874</div>
                        <div class="item2">处方流转</div>
                    </div>
                    <div class="item">
                        <div class="item1">25249</div>
                        <div class="item2">其他</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 95%" v-if="tan == 'znyxtx'">
                    <div class="item">
                        <div class="item1">1625</div>
                        <div class="item2">智能药箱提醒总数</div>
                    </div>
                    <div class="item">
                        <div class="item1">1219</div>
                        <div class="item2">服务提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">163</div>
                        <div class="item2">复购提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">114</div>
                        <div class="item2">近效提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">81</div>
                        <div class="item2">回收提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">48</div>
                        <div class="item2">过期提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">16</div>
                        <div class="item2">禁忌提醒数</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 95%" v-if="tan == 'znyxtxfx'">
                    <div class="item">
                        <div class="item1">1219(75%)</div>
                        <div class="item2">服务提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">163(10%)</div>
                        <div class="item2">复购提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">114(7%)</div>
                        <div class="item2">近效提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">81(4%)</div>
                        <div class="item2">回收提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">48(3%)</div>
                        <div class="item2">过期提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">16(1%)</div>
                        <div class="item2">禁忌提醒数</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 95%" v-if="tan == 'znyxhyd'">
                    <div class="item">
                        <div class="item1">1625</div>
                        <div class="item2">智能药箱总数</div>
                    </div>
                    <div class="item">
                        <div class="item1">1430</div>
                        <div class="item2">近7天活跃</div>
                    </div>
                    <div class="item">
                        <div class="item1">88%</div>
                        <div class="item2">活跃度</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 50%" v-if="tan == 'ysfwxyl'">
                    <div class="item">
                        <div class="item1">58</div>
                        <div class="item2">签约医生数量</div>
                    </div>
                    <div class="item">
                        <div class="item1">93%</div>
                        <div class="item2">平局响应率</div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--今日预警-->
        <el-dialog :visible.sync="dialogMessage" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogMessage = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    今日预警
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 80px;text-align: center">序号</td>
                            <td>企业名称</td>
                            <td style="width: 156px;text-align: center">预警类型</td>
                            <td>预警信息</td>
                            <td>预警时间</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in listData.list">
                            <td style="height: 120px" class="tx-c">
                                <div class="cell">{{ i + 1 }}</div>
                            </td>
                            <td>
                                <div class="cell">{{ x.storeName }}</div>
                            </td>
                            <td>
                                <div class="cell tx-c">{{ x.typeName }}</div>
                            </td>
                            <td>
                                <div class="cell" style="width: 510px">
                                    {{ x.content.replace(x.storeName, "") }}
                                </div>
                            </td>
                            <td>
                                <div style="width: 113px">{{ x.createDate }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postMessage.page"
                            :total="listData.total"
                            :page-size="postMessage.count"
                            @pagination="getMessagePage"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--责令整改，现场检查-->
        <el-dialog :visible.sync="dialogZlzg" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogZlzg = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    <span v-if="tanType == 10">责令整改</span>
                    <span v-if="tanType == 11">现场检查</span>
                </div>
                <div class="biao">
                    <div style="line-height: 200px;text-align: center;color: white">暂无数据</div>
                </div>
            </div>
        </el-dialog>
        <!--进销存动态分析-->
        <el-dialog
            :visible.sync="dialogJxc"
            width="1600px"
            top="5vh"
            @close="closed"
            :destroy-on-close="true"
        >
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogJxc = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    <span>进销存动态分析</span>
                </div>
                <div class="biao">
                    <table width="100%" cellspacing="0" cellpadding="0" class="listtable">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>企业名称</td>
                            <td>药品类别</td>
                            <td>当前库存</td>
                            <td>分析预警</td>
                            <td>提示状态</td>
                        </tr>
                        <tr class="listData jxc">
                            <td style="height: 120px">1</td>
                            <td>浦江县恒生药房连锁有限公司亚太大道店</td>
                            <td>疫情药品</td>
                            <td>24323</td>
                            <td>溢货</td>
                            <td>已提示</td>
                        </tr>
                        <tr class="listData jxc">
                            <td style="height: 120px">2</td>
                            <td>浙江诚心大药房连锁有限公司浦江白马店</td>
                            <td>疫情药品</td>
                            <td>12563</td>
                            <td>溢货</td>
                            <td>已提示</td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--销售记录列表-->
        <el-dialog :visible.sync="dialogSale" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogSale = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    销售记录
                </div>
                <div class="biao">
                    <div v-if="showSaleSearch" class="flex saleSearch">
                        <div class="label">订单号</div>
                        <div class="item">
                            <el-input v-model="postSale.orderSn" clearable></el-input>
                        </div>
                        <div class="label">商品通用名</div>
                        <div class="item">
                            <el-input v-model="postSale.goodsName" clearable></el-input>
                        </div>
                        <div class="label">企业名称</div>
                        <div class="item">
                            <el-select
                                v-model="postSale.storeIds"
                                filterable
                                placeholder="请选择"
                                clearable
                            >
                                <el-option
                                    v-for="item in storeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="label">日期</div>
                        <div class="item">
                            <el-date-picker
                                v-model="saleTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            >
                            </el-date-picker>
                        </div>
                        <div class="item flex" style="align-items: center">
                            <div @click="searchSale" class="btnSearch">
                                <img src="../../assets/smart/home/s.png" />
                            </div>
                        </div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 120px">订单号</td>
                            <td style="">通用名称</td>
                            <td style="width:130px">规格</td>
                            <td style="width: 50px" class="tx-c">数量</td>
                            <td style="width: 50px" class="tx-c">单位</td>
                            <td style="width: 206px">生产企业</td>
                            <td style="width: 122px">批准文号</td>
                            <td style="width: 80px">批号</td>
                            <td style="width: 109px">有效期至</td>
                            <td style="width: 109px">销售日期</td>
                            <td style="">企业名称</td>
                        </tr>
                        <tr class="listData tdSale" v-for="x in saleData.list">
                            <td style="height: 107px">{{ x.orderSn }}</td>
                            <td>{{ x.commonName }}</td>
                            <td>
                                <div class="overtwo" style="width: 110px">{{ x.spec }}</div>
                            </td>
                            <td>
                                <div class="shuzi tx-c">{{ x.quantity }}</div>
                            </td>
                            <td class="tx-c">{{ x.unit }}</td>
                            <td style="text-align: left;">
                                <div class="overtwo">{{ x.company }}</div>
                            </td>
                            <td>{{ x.approval }}</td>
                            <td>
                                <div class="">{{ x.lot }}</div>
                            </td>
                            <td>
                                <div class="nowrap">{{ x.validDate }}</div>
                            </td>
                            <td>
                                <div class="nowrap">{{ x.createDate.split(" ")[0] }}</div>
                            </td>
                            <td style="text-align: left">
                                <div class="overtwo">{{ x.storeName }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postSale.page"
                            :total="saleData.total"
                            :page-size="postSale.count"
                            @pagination="getSale"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--库存记录列表-->
        <el-dialog :visible.sync="dialogStock" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogStock = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    库存记录
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 220px">商品编码</td>
                            <td>商品名</td>
                            <td>通用名称</td>
                            <td>规格</td>
                            <td style="width: 50px">单位</td>
                            <td>生产企业</td>
                            <td>批准文号</td>
                            <td style="width: 150px">批号</td>
                            <td style="width: 150px">有效期至</td>
                            <td style="width: 191px">企业名称</td>
                            <td style="width: 50px">库存</td>
                            <td style="width: 83px">更新时间</td>
                        </tr>
                        <tr class="listData" v-for="x in stockData.list">
                            <td>{{ x.goodsId }}</td>
                            <td>{{ x.goodsName }}</td>
                            <td>{{ x.defaultName }}</td>
                            <td>{{ x.spec }}</td>
                            <td>{{ x.unit }}</td>
                            <td>{{ x.company }}</td>
                            <td>{{ x.approval }}</td>
                            <td>{{ x.lot }}</td>
                            <td>{{ x.validDate }}</td>
                            <td>{{ x.storeName }}</td>
                            <td>{{ x.quantity }}</td>
                            <td>{{ x.updateDate }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postStock.page"
                            :total="stockData.total"
                            :page-size="postStock.count"
                            @pagination="getStock"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--执业药师-->
        <el-dialog :visible.sync="dialogPharmacist" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogPharmacist = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    执业药师
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 50px" class="tx-c">序号</td>
                            <td class="tx-c">执业药师姓名</td>
                            <td>执业药师身份证</td>
                            <td style="width: 500px">机构／部门</td>
                            <td class="tx-c">登记地区</td>
                            <td>登记时间</td>
                            <td class="tx-c">资格证书</td>
                            <td class="tx-c">注册证书</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in pharmacistData.list">
                            <td class="tx-c" style="height: 125px">{{ i + 1 }}</td>
                            <td class="tx-c">{{ x.name }}</td>
                            <td>{{ sfz(x.idcard) }}</td>
                            <td>{{ x.storeName }}</td>
                            <td class="tx-c">{{ x.region }}</td>
                            <td>
                                <div class="time">{{ x.createDate }}</div>
                            </td>
                            <td class="tx-c">
                                <div v-if="x.attr.indexOf('license') != -1">是</div>
                                <div v-else>否</div>
                            </td>
                            <td class="tx-c">
                                <div v-if="x.image" type="success">是</div>
                                <div v-else>否</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postPharmacist.page"
                            :total="pharmacistData.total"
                            :page-size="postPharmacist.count"
                            @pagination="getPharmacist"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--药店列表-->
        <el-dialog :visible.sync="dialogStore" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogStore = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    药店
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 220px">药店名称</td>
                            <td>企业代码</td>
                            <td>联系人</td>
                            <td>联系电话</td>
                            <td style="width: 200px">药店地址</td>
                            <td>省药监编码</td>
                            <td>状态</td>
                            <td>最近更新时间</td>
                        </tr>
                        <tr
                            class="listData dabiaozi"
                            :class="{ ban: i % 2 == 1 }"
                            v-for="(x, i) in storeData.list"
                        >
                            <td style="height: 116px">{{ x.name }}</td>
                            <td>{{ x.code }}</td>
                            <td>{{ x.contact }}</td>
                            <td>{{ mbl(x.phone) }}</td>
                            <td>{{ x.address }}</td>
                            <td>{{ x.manageCode }}</td>
                            <td>{{ x.easyCode }}</td>
                            <td>
                                <div class="time">{{ x.updateDate }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postStore.page"
                            :total="storeData.total"
                            :page-size="postStore.count"
                            @pagination="getStore"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--云药房列表-->
        <el-dialog :visible.sync="dialogStoreCloud" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogStoreCloud = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    云药房
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 220px">药店名称</td>
                            <td>企业代码</td>
                            <td>联系人</td>
                            <td>联系电话</td>
                            <td style="width:450px">药店地址</td>
                            <td>省药监编码</td>
                            <td>状态</td>
                        </tr>
                        <tr class="listData dabiaozi" v-for="x in storeCloudData.list">
                            <td style="height: 120px">{{ x.name }}</td>
                            <td>{{ x.code }}</td>
                            <td>{{ x.contact }}</td>
                            <td>{{ mbl(x.phone) }}</td>
                            <td>{{ x.address }}</td>
                            <td>{{ x.manageCode }}</td>
                            <td>{{ x.easyCode }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postStore.page"
                            :total="155"
                            :page-count="postStore.pageCount"
                            @pagination="getStore"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--商品信息-->
        <el-dialog :visible.sync="dialogGoods" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogGoods = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    商品信息
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>订单号</td>
                            <td>商品名</td>
                            <td>通用名称</td>
                            <td>规格</td>
                            <td style="">数量</td>
                            <td>单位</td>
                            <td>批号</td>
                            <td>批准文号</td>
                            <td>生产企业</td>
                            <td>药店名称</td>
                            <td>有效期</td>
                        </tr>
                        <tr class="listData dabiaozi" v-for="x in goodsData">
                            <td style="height: 120px">{{ x.orderSn }}</td>
                            <td>{{ x.goodsName }}</td>
                            <td>{{ x.commonName }}</td>
                            <td>{{ x.spec }}</td>
                            <td>
                                <span class="shuzi">{{ x.quantity }}</span>
                            </td>
                            <td>{{ x.unit }}</td>
                            <td>{{ x.lot }}</td>
                            <td>{{ x.approval }}</td>
                            <td>
                                <div class="overtwo">{{ x.company }}</div>
                            </td>
                            <td>
                                <div class="overtwo">{{ x.storeName }}</div>
                            </td>
                            <td class="nowrap">{{ x.validDate }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--签约医生-->
        <el-dialog :visible.sync="dialogDoctor" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogDoctor = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    签约医生
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 150px">姓名</td>
                            <td>联系电话</td>
                            <td>服务村</td>
                            <td class="tx-c">服务人口数</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in doctorData2">
                            <td class="txt-c" style="height: 120px">{{ x.name }}</td>
                            <td>{{ mbl(x.tel) }}</td>
                            <td>{{ x.cun }}</td>
                            <td>
                                <div class="shuzi">{{ x.ren }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postDoctor.page"
                            :total="doctorData.total"
                            :page-size="postDoctor.count"
                            @pagination="getDoctor"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--预警数-->
        <el-dialog
            :visible.sync="dialogWarning"
            width="1600px"
            top="5vh"
            @close="closed"
            destroy-on-close
        >
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogWarning = false" class="btnBack hand">
                        <img src="../../assets/smart/home/close.png" />
                    </div>
                    预警列表
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 80px" class="tx-c">序号</td>
                            <td>企业名称</td>
                            <td style="width: 156px">预警类型</td>
                            <td>预警信息</td>
                            <td>预警时间</td>
                        </tr>
                        <tr class="listData tdSale" v-for="(x, i) in warningData.list">
                            <td style="height:120px" class="tx-c">
                                <div class="cell">{{ i + 1 }}</div>
                            </td>
                            <td>
                                <div class="">{{ x.storeName }}</div>
                            </td>
                            <td>
                                <div class="">{{ x.typeName }}</div>
                            </td>
                            <td><div style="width: 510px" v-html="x.content"></div></td>
                            <td>
                                <div style="width: 109px">{{ x.createDate }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager
                            :page="postWarning.page"
                            :total="warningData.total"
                            :page-size="postWarning.count"
                            @pagination="getWarning"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<style scoped>
.singleField {
    padding-left: 20px;
}
.pici {
    padding: 0px 3px;
    height: 30px;
    margin-right: 5px;
    line-height: 30px;
    text-align: center;
    background: rgba(2, 119, 250, 0.2);
    border: 1px solid #0277fa;
    border-radius: 4px;
}
.overtwo {
    width: 200px;

    word-break: break-all;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 2; /* 这里是超出几行省略 */

    overflow: hidden;
}
.shuzi {
    white-space: nowrap;
    color: #40a0ff;
    font-size: 30px;
    text-align: center;
}
.time {
    width: 109px;
}
.nowrap {
    white-space: nowrap;
}
/*滚动条----------------------------开始*/
.scrbar::-webkit-scrollbar {
    width: 10px;
    height: 1px;
}
.scrbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0 0 0 0.2);
    background: #13c2f8;
}
.scrbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0 0 0 0.2);
    border-radius: 10px;
    background: #010324;
}
/*滚动条----------------------------------结束*/
.tymBox {
    justify-content: flex-start;
    flex-wrap: wrap;
    color: #00b7ff;
    overflow: auto;
    height: 283px;
}
.tym {
    width: 25%;
    line-height: 200%;
    font-size: 18px;
}
.btn {
    cursor: pointer;
    border: 1px solid #00b7ff;
    border-radius: 5px;
    padding: 5px 15px;
}
.chakan {
    height: 160px;
    padding-top: 25px;
}
.listData2 > td {
    color: #fff;
    font-size: 20px;
}
.viewContent tr td {
    line-height: 200%;
}
.viewContent {
    top: 90px;
    left: 30px;
    border: 1px solid #026d97;
    width: 1439px;
    padding: 0px 1px;
    position: absolute;
}
.tdBtn {
    height: 136px;
}
.tdContent {
}
.zgbtn1 {
    background: url("../../assets/smart/home/yzg.png");
    background-size: 100% 100%;
    width: 235px;
    height: 68px;
    line-height: 68px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}
.ztbtn2 {
    background: url("../../assets/smart/home/wzg.png");
    background-size: 100% 100%;
    width: 235px;
    height: 68px;
    line-height: 68px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}
.yzg {
    background: url("../../assets/smart/home/yzg.png");
    background-size: 100% 100%;
    width: 235px;
    height: 68px;
    line-height: 68px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}
.wzg {
    background: url("../../assets/smart/home/wzg.png");
    background-size: 100% 100%;
    width: 235px;
    height: 68px;
    line-height: 68px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}
.pager {
    align-items: center;
    color: #fff;
    justify-content: center;
}
.page {
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: rgba(1, 4, 41, 0.8);
    border-radius: 3px;
    border: 1px solid #1ac9ff;
    color: #16a6d9;
    text-align: center;
}
.zzzg {
    justify-content: space-between;
    padding: 0px 20px;
    padding-top: 20px;
}
.zzzg .item {
    width: 150px;
    cursor: pointer;
}
.zzzg .item1 {
    font-size: 24px;
    background: url("../../assets/smart/home/zzzg.png");
    background-size: 100% 100%;
    width: 150px;
    height: 56px;
    line-height: 56px;
    text-align: center;
}
.zzzg .item2 {
    color: #fff;
    text-align: center;
    margin-top: 10px;
}
.tdView {
    height: 230px;
}
.btnBack {
    position: absolute;
    right: 20px;
    /*background: url("../../assets/smart/home/back.png");*/
    background-size: 100% 100%;
    font-size: 20px;
    font-weight: normal;
    padding: 5px 10px;
}
.btnDetail {
    background: url("../../assets/smart/home/btn_view.png");
    background-size: 100% 100%;
    width: 108px;
    height: 30px;
    line-height: 30px;
    color: #00b7ff;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
}
.btnDetail2 {
    background: url("../../assets/smart/home/btn_view2.png");
    background-size: 100% 100%;
    width: 108px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
}
.boxcontent {
    border: 2px solid rgba(74, 144, 255, 0.5);
    position: absolute;
    width: 1536px;
    height: 175px;
    margin-top: 46px;
}
.overflow {
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}
.huizong {
    display: flex;
    margin-bottom: 10px;
}
.flex_vc {
    display: flex;
    align-items: center;
    justify-content: center;
}
.huizong .btnHui {
    margin-right: 10px;
    color: #00b7ff;
    font-size: 20px;
    background-image: url("../../assets/smart/home/huibtn.png");
    background-size: 100% 100%;
    width: 151px;
    height: 52px;
}
.pager {
    position: absolute;
    bottom: 50px;
    width: 100%;
}
.tag >>> .el-tag {
    font-size: 20px;
}
.dazi .listData > td {
    font-size: 20px;
}
.tanContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    margin: 0 auto;
}
.tanContent .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 229px;
    height: 194px;
    background-image: url("../../assets/smart/home/dialog3.png");
    background-size: 100% 100%;
}
.tanContent .item1 {
    font-family: Arial;
    font-size: 30px;
    color: #febe13;
    line-height: 150%;
}
.tanContent .item2 {
    font-size: 16px;
    color: #fff;
    line-height: 200%;
}
.flex {
    display: flex;
}
.btnSearch {
    background-repeat: no-repeat;
    background-position: center;
    color: #01b7ff;
    cursor: pointer;
    user-select: none;
    font-weight: bolder;
    text-align: center;
    font-size: 16px;
}
.saleSearch {
    margin-bottom: 20px;
}
.saleSearch >>> .el-select__tags {
    background-color: transparent;
    flex-wrap: nowrap;
    overflow: hidden;
}
.saleSearch >>> .el-input__inner {
    background-color: transparent;
    font-size: 20px;
    width: 220px;
    color: #00b7ff;
    border: 1px solid #00b7ff;
}
.saleSearch .item {
    margin-right: 15px;
}
.saleSearch .label {
    padding-right: 5px;
    color: #00b7ff;
    line-height: 44px;
    font-size: 20px;
}

.biao {
    padding-top: 10px;
}
.jxc td {
    padding-left: 10px;
    height: 120px;
}
.hand {
    cursor: pointer;
}
.listData .name {
    line-height: 80px;
}
.dabiaozi > td {
    padding: 20px 10px;
    line-height: 150%;
}
.tx-c {
    text-align: center;
}
.td-c td {
    text-align: center;
}
.tdSale > td {
    padding-left: 7px;
    padding-right: 7px;
    line-height: 140%;
}
.listData > td {
    height: 100px;
    color: #fff;
    vertical-align: middle;
    font-size: 20px;
    padding: 0 7px;
}
.listData:nth-child(odd) {
    background-color: rgba(2, 119, 250, 0.8);
}
.listtable {
    width: 100%;
    border-collapse: collapse;
}
.listTitle > td {
    padding: 0 7px;
    white-space: nowrap;
}
.listTitle {
    color: #fff;
    background: rgba(2, 119, 250, 0.9);
    height: 46px;
    font-size: 18px;
    line-height: 46px;
}
.biao2 td {
    padding: 0px 7px;
    line-height: 30px;
}
.listTitle2 td {
    padding: 0px 7px;
}
.listTitle2 {
    color: #fff;
    background: rgba(2, 119, 250, 0.5);
    line-height: 26px;
    font-size: 18px;
}
.listData {
    color: #ffffff;
}

.btnBack {
    position: absolute;
    right: 20px;
    background-size: 100% 100%;
    font-size: 20px;
    font-weight: normal;
    padding: 5px 10px;
}
.btnBack2 {
    position: absolute;
    right: 20px;
    /*background: url("../../assets/smart/home/dialog2.png");*/
    background-repeat: no-repeat;
    background-position: center left;
    font-size: 20px;
    font-weight: normal;
    padding: 5px 10px 5px 20px;
}
.tanTitle {
    position: relative;
    /*background: url("../../assets/smart/home/titbg.png");*/
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    color: #fff;
    font-size: 26px;
    font-weight: bolder;
    letter-spacing: 4px;
    margin-bottom: 20px;
}
.tanTitle2 {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    color: #00b7ff;
    font-size: 26px;
    font-weight: bolder;
    letter-spacing: 7px;
    margin-bottom: 20px;
    padding-top: 50px;
}
.tanWin {
    background: url("../../assets/smart/home/bg.png");
    background-size: 100% 100%;
    height: 835px;
    padding: 20px 30px;
    position: relative;
}
.tanWin2 {
    background: url("../../assets/smart/home/dialog1.png");
    background-size: 100% 100%;
    height: 428px;
    padding: 20px 30px;
}

.pageTitle {
    font-weight: bolder;
    letter-spacing: 10px;
    text-align: center;
}
/*el-dialog样式------------------------------------start*/
.dialog >>> .el-dialog {
    background-color: transparent;
}
.dialog >>> .el-dialog__header {
    display: none;
}
.dialog >>> .el-dialog__wrapper .el-dialog .el-dialog__body {
    padding: 0;
}
/*el-dialog样式------------------------------------end*/
/*el-table样式------------------------------------start*/
.biao >>> .el-table,
.biao >>> .el-table__expanded-cell {
    background-color: transparent !important;
}

.biao >>> .el-table th,
>>> .el-table tr {
    border: 0 !important;
    background-color: transparent !important;
}
/*鼠标移上去颜色*/
.biao >>> .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(0, 85, 255, 0.29);
}

/*行分隔线*/
.biao >>> .el-table__row > td {
    color: #f5fdff;
    border-bottom: 1px solid #004f68;
}

/* 去掉最下面的那一条线 */
.biao >>> .el-table::before {
    height: 0px;
}

/*el-table样式------------------------------------end*/
</style>
<script>
import moment from "moment";
import {
    saleList,
    storeList,
    control,
    messagePage,
    stockList,
    storeLevel,
    disposalList,
    pharmacistData,
    task,
    storeListPage,
} from "@/api/yaojian";
import pager from "./pager";
import dtpj from "@/assets/data/dtpj.json";
import ldcz from "@/assets/data/ldcz.json";
import ysfwxyl from "@/assets/data/ysfwxyl.json";
import doctor from "@/assets/data/doctor.json";
export default {
    name: "chuan",
    components: { pager },
    data() {
        return {
            warning: "",
            yjsZt: 1,
            dialogWarning: false,
            warningData: [],
            postWarning: {
                page: 1,
                count: 5,
            },
            //签约医生---s
            dialogDoctor: false,
            postDoctor: {
                page: 1,
                count: 5,
            },
            doctorData: { total: 58, list: doctor },
            //签约医生---end
            postStore: {
                //药店
                page: 1,
                count: 5,
            },
            storeData: [],
            dialogStore: false,
            dialogStoreCloud: false,
            pharmacistData: [],
            postPharmacist: {
                //执业药师
                page: 1,
                count: 5,
            },
            dialogPharmacist: false,
            ysfwxylData: ysfwxyl,
            postYsfwxyl: { page: 1, count: 5 },
            dialogYsfwxyl: false,
            contorl: [],
            contorl2: [],
            dialogControl2: false,
            dialogZlzg: false,
            ldczData: ldcz,
            bmczZt: 1,
            yjczBtn: 1,
            bmczCount1: 0,
            bmczCount2: 0,
            bmczData: [],
            tanType: 1,
            zgzt: 1,
            zzzgData: [],
            yzgData: [],
            wzgData: [],
            post: { page: 1, count: 5, status: 40 },
            post2: { page: 1, count: 5 },
            pageCount: 0,
            dialogVisible: false,
            dtpjDataTotal: 0,
            dialogStock: false,
            stockData: [],
            postStock: { page: 1, count: 5, storeIds: 1 },
            showSaleSearch: true,
            arrDate: [
                moment().format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("week")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("month")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("quarter")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("year")
                    .format("yyyy-MM-DD 00:00:00"),
            ],
            saleData: [],
            postSale: {
                page: 1,
                count: 5,
                startTime: moment().format("yyyy-MM-DD 00:00:01"),
                endTime: moment().format("yyyy-MM-DD 23:59:59"),
                type: 1,
            },
            dialogSale: false,
            dialogJxc: false,
            postMessage: { page: 1, count: 5 },
            listData: [],
            dialogMessage: false,
            dialogDtpj: false,
            dtpj: "",
            dtpjData2: dtpj,
            dtpjData: {},
            postDtpj: { page: 1, count: 5 },
            xyjjData: [],
            xyjjData2: [],
            postXyjj: { page: 1, count: 5 },
            dialogCzjg: false,
            czjg: "",
            tan: "satisfaction",
            dialogSatisfaction: false,
            storeIds: "",
            saleTime: "",
            prescriptionTime: "",
            storeList: [],
            prescriptionTitle: "处方药",
            dialogPrescription: false,
            prescriptionData: [],
            postPrescription: {
                page: 1,
                count: 5,
                isPrescription: 1,
                hospital: "互联网医院",
                startTime: moment().format("yyyy-MM-DD 00:00:01"),
                endTime: moment().format("yyyy-MM-DD 23:59:59"),
                type: 1,
            },
            dialogControl: false,
            controlData: [],
            postControl: {
                page: 1,
                count: 5,
            },
            goodsData: [],
            dialogGoods: false,
        };
    },
    computed: {
        doctorData2() {
            let start = (this.postDoctor.page - 1) * this.postDoctor.count;
            let arr = this.doctorData.list.slice(start, start + this.postDoctor.count);
            return arr;
        },
        storeCloudData() {
            let data = JSON.parse(JSON.stringify(this.storeData));
            if (this.postStore.page == 10) {
                data.list = this.storeData.list.slice(0, this.postStore.count);
            }
            return data;
        },
        ysfwxylData2() {
            let start = (this.postYsfwxyl.page - 1) * this.postYsfwxyl.count;
            let arr = this.ysfwxylData.slice(start, start + 5);
            return arr;
        },
        perHui() {
            // let p=Math.floor(this.dtpjData.total*100/this.dtpjDataTotal)
            let p = (this.dtpjData.total * 100) / this.dtpjDataTotal;
            return p.toFixed(1);
        },
    },
    created() {},
    methods: {
        mbl(t) {
            t = t.substring(0, 3) + "****" + t.substring(7);
            return t;
        },
        sfz(t) {
            t = t.substring(0, 6) + "********" + t.substring(14);
            return t;
        },
        openWarning(k, t) {
            this.yjsZt = t;
            this.warning = k;
            this.dialogWarning = true;
            this.getWarning();
        },
        async getWarning(data) {
            //全预警信息
            if (data) {
                this.postWarning.page = data.page;
            }
            let start = "2022-01-01";
            if (this.yjsZt == 1) {
                start = this.arrDate[0];
            }
            if (this.yjsZt == 2) {
                start = this.arrDate[1];
            }
            if (this.yjsZt == 3) {
                start = this.arrDate[2];
            }
            if (this.yjsZt == 4) {
                start = this.arrDate[3];
            }
            if (this.yjsZt == 5) {
                start = this.arrDate[4];
            }
            if (this.warning == "all") {
                start = "2022-01-01";
            }
            start += " 00:00:00";
            let end = moment().format("yyyy-MM-DD 23:59:59");
            if (!this.postWarning.page) {
                this.postWarning.page = 1;
            }
            let post = {
                ...this.postWarning,
                start: start,
                end: end,
            };
            console.log(post);
            let rs = await messagePage(post);
            if (rs.code == 200) {
                this.warningData = rs.data;
                this.warningData.list.map((x) => {
                    x.typeName = x.title.split("-")[0];
                    x.content = x.content.replace(x.storeName, "").replace("，", "<br>");
                });
            }
        },
        getDoctor(data) {
            if (data) {
                this.postDoctor.page = data.page;
            }
        },
        openGoods(o) {
            this.goodsData = [];
            this.goodsData.push(o);
            console.log(this.goodsData);
            this.dialogGoods = true;
        },
        getStore(data) {
            if (data) {
                this.postStore.page = data.page;
            }
            storeListPage(this.postStore).then((rs) => {
                this.storeData = rs.data;
            });
        },
        openStore(t) {
            if (t == 2) {
                this.dialogStoreCloud = true;
            } else {
                this.dialogStore = true;
            }
            this.getStore();
        },
        openPharmacist() {
            this.dialogPharmacist = true;
            this.getPharmacist();
        },
        getPharmacist(data) {
            if (data) {
                this.postPharmacist.page = data.page;
            }
            pharmacistData(this.postPharmacist).then((rs) => {
                this.pharmacistData = rs.data;
                this.pharmacistData.total = parseInt(rs.data.total);
            });
        },
        getYsfwxyl(data) {
            if (data) {
                this.postYsfwxyl.page = data.page;
            }
        },
        openYsfwxyl() {
            this.dialogYsfwxyl = true;
        },
        openZlzg(t) {
            this.tanType = t;
            this.dialogZlzg = true;
        },
        //部门处置---------------------start
        status(id) {
            let lxm = [];
            lxm[10] = "待下发";
            lxm[20] = "待检查";
            lxm[30] = "已完成";
            return lxm[id];
        },
        zflx(id) {
            let lxm = [];
            lxm[10] = "有因检查";
            lxm[20] = "专项检查";
            lxm[30] = "飞行检查";
            return lxm[id];
        },
        search2(status = "") {
            this.bmczZt = status;
            this.post2.page = 1;
            this.getBmcz();
        },
        closeYjcz() {
            this.yjczBtn = 0;
        },
        getBmcz() {
            if (this.bmczZt == 0) {
                this.bmczData = [];
                this.pageCount2 = 0;
                return;
            }
            let startTime = "2022-04-29 00:00:00";
            let endTime = "2030-04-29 00:00:00";
            this.post2.startTime = startTime;
            this.post2.endTime = endTime;
            this.post2.statusText = "20,30";
            task(this.post2).then((rs) => {
                this.bmczData = rs.data;
                this.pageCount2 = Math.ceil(rs.data.total / this.post2.count);
            });
        },
        getBmczCount() {
            let startTime = "2022-04-29 00:00:00";
            let endTime = "2030-04-29 00:00:00";
            task({ page: 1, count: 10, status: 30, startTime: startTime, endTime: endTime }).then(
                (rs) => {
                    this.bmczCount1 = rs.data.total;
                }
            );
            task({ page: 1, count: 10, status: 20, startTime: startTime, endTime: endTime }).then(
                (rs) => {
                    this.bmczCount2 = rs.data.total;
                }
            );
        },
        changePage2(data) {
            this.post2.page = data.page;
            this.getBmcz();
        },
        //部门处置---------------------end
        shou(o) {
            this.ldczData.map((x) => {
                if (o.a1 == x.a1) {
                    this.$set(o, "isView", !o.isView);
                } else {
                    x.isView = false;
                }
            });
        },
        changeYjcz(t) {
            this.yjczBtn = t;
            if (t == 2) {
                this.search2(1);
            }
        },
        changePage(data) {
            this.post.page = data.page;
            this.getZzzg();
        },
        openTan(t = 1, btnZt = 1) {
            //t:1自主整改，2，部门处置 3，联动处置，0所有
            this.getBmczCount();
            if (t == 0) {
                this.yjczBtn = 1;
                this.search();
            }
            if (t == 1) {
                this.search();
            }
            if (t == 2) {
                let status = btnZt;
                this.bmczZt = status;
                this.search2(status);
            }
            this.tanType = t;
            this.dialogVisible = true;
        },
        search(t = 1) {
            if (t == 1) {
                this.post.status = 40;
            }
            if (t == 2) {
                this.post.status = 10;
            }
            if (this.tanType == 0) {
                this.post.status = null;
            }
            this.post.page = 1;
            this.zgzt = t;
            this.getZzzg();
        },
        getZzzg() {
            this.post.status = 40;
            disposalList(this.post).then((rs) => {
                let data = rs.data;
                data.list.map((x) => {
                    if (x.repayPic) {
                        if (x.repayPic.indexOf("]") != -1) {
                            x.repayPic = JSON.parse(x.repayPic);
                        } else {
                            x.repayPic = [x.repayPic];
                        }
                    }
                    if (x.completeName) {
                        x.completeName = x.completeName.split(":")[1];
                    }
                });
                this.zzzgData = data;
                this.pageCount = Math.ceil(this.zzzgData.total / this.post.count);
            });
        },
        getYzg() {
            let post = { page: 1, count: 10, status: 40 };
            disposalList(post).then((rs) => {
                this.yzgData = rs.data;
            });
        },
        getWzg() {
            let post = { page: 1, count: 10, status: 10 };
            disposalList(post).then((rs) => {
                this.wzgData = rs.data;
            });
        },
        openStock() {
            this.dialogStock = true;
            this.getStoreList();
            this.getStock();
        },
        openSale() {
            this.dialogSale = true;
            this.getStoreList();
            this.getSale();
        },
        getStock(data) {
            if (data) {
                this.postStock.page = data.page;
            }
            stockList(this.postStock).then((rs) => {
                this.stockData = rs.data;
            });
        },
        searchSale() {
            if (!this.saleTime) {
                this.saleTime = moment().format("YYYY-MM-DD");
            }
            this.postSale.startTime = this.saleTime + " 00:00:00";
            this.postSale.endTime = this.saleTime + " 23:59:59";
            this.postSale.page = 1;
            this.getSale();
        },
        getSale(data) {
            if (data) {
                this.postSale.page = data.page;
            }
            saleList(this.postSale).then((rs) => {
                this.saleData = rs.data;
            });
        },
        async getMessagePage(data) {
            //预警信息
            if (data) {
                this.postMessage.page = data.page;
            }
            let rs = await messagePage(this.postMessage);
            if (rs.code == 200) {
                this.listData = rs.data;
                this.listData.list.map((x) => {
                    x.typeName = x.title.split("-")[0];
                });
            }
        },
        openMessage() {
            //今日预警
            this.getMessagePage();
            this.dialogMessage = true;
        },
        openJxc() {
            this.dialogJxc = true;
        },
        getDtpj(data) {
            if (data) {
                this.postDtpj.page = data.page;
            }
            this.postDtpj.level = this.dtpj.replace("级", "");
            storeLevel(this.postDtpj).then((rs) => {
                this.dtpjData = rs.data;
            });
        },
        getDtpjTotal() {
            storeLevel({ page: 1, count: 1 }).then((rs) => {
                this.dtpjDataTotal = rs.data.total;
            });
        },
        openDtpj(name) {
            this.dtpj = name;
            this.dialogDtpj = true;
            this.getDtpjTotal();
            this.getDtpj();
        },
        getXyjj(data) {
            if (data) {
                this.postXyjj.page = data.page;
            }
            let start = (this.postXyjj.page - 1) * this.postXyjj.count;
            this.xyjjData = this.xyjjData2.slice(start, start + this.postXyjj.count);
        },
        openCzjg(name) {
            this.getStoreList();
            this.czjg = name;
            this.dialogCzjg = true;
        },
        formatTime(t) {
            let s = t.split(" ")[0];
            return s;
        },
        openSatisfaction(t) {
            this.tan = t;
            this.dialogSatisfaction = true;
        },
        getStoreList() {
            storeList().then((rs) => {
                this.storeList = rs.data;

                //信用降级数据
                this.xyjjData2 = rs.data;
                this.xyjjData2.sort(function() {
                    return Math.random() - 0.5;
                });
                this.xyjjData2 = this.xyjjData2.slice(0, 58);
                this.getXyjj();
                //信用降级数据
            });
        },
        closed() {
            //关闭初始化
            this.postDtpj.page = 1;
            this.postPrescription.page = 1;
            this.postXyjj.page = 1;
        },
        openControl2(o) {
            this.dialogControl2 = true;
            this.contorl = o.names ? JSON.parse(o.names) : [];
            this.contorl2 = o.ids ? JSON.parse(o.ids) : [];
        },
        openControl() {
            this.dialogControl = true;
            this.getControl();
        },
        getControl(data) {
            if (data) {
                this.postControl.page = data.page;
            }
            control(this.postControl).then((rs) => {
                this.controlData = rs.data;
            });
        },
        openPrescription(tit) {
            this.prescriptionTitle = tit;
            this.dialogPrescription = true;
            this.getStoreList();
            this.getPrescription();
        },
        searchPrescription() {
            this.postPrescription.page = 1;
            this.getPrescription();
        },
        getPrescription(data) {
            if (data) {
                this.postPrescription.page = data.page;
            }
            if (this.prescriptionTime) {
                this.postPrescription.startTime = this.prescriptionTime + " 00:00:00";
                this.postPrescription.endTime = this.prescriptionTime + " 23:59:59";
            } else {
                this.postPrescription.startTime = moment()
                    .add(-7, "day")
                    .format("yyyy-MM-DD 00:00:00");
                this.postPrescription.endTime = moment().format("yyyy-MM-DD 23:59:59");
            }
            saleList(this.postPrescription).then((rs) => {
                this.prescriptionData = rs.data;
            });
        },
    },
};
</script>

<style scoped></style>
