<template>
  <div class="dialog">
    <el-dialog :visible.sync="dialogShow" width="1600px" top="5vh" @close="close()" :destroy-on-close="true">
      <div class="tanWin">
        <div class="tanTitle">
          <div @click="dialogShow = false" class="btnBack hand">
            <img :src="closeImg" />
          </div>
          {{title}}
        </div>
        <div class="biao">
          <table class="listtable">
            <tr class="listTitle">
              <td v-for="x in attr" :style="x.style">{{x.label}}</td>
            </tr>
            <tr class="listData dabiaozi" v-for="(x, i) in tableData" :key="i">
              <td v-for="y in attr" :style="y.style2">
               <div v-if="y.value=='index'" class="txc">{{ i + 1 }}</div>
               <div v-if="y.type=='tel'">{{ mbl(x[y.value]) }}</div>
               <div v-else-if="y.type=='pic'" style="display: flex;">
                 <div style="background-color: #FFFFFF" @click="isPic=true">
                   <el-image
                       style="width: 100px; height: 50px"
                       :src="$getImageUrl(x[y.value])"
                       >
                     <div slot="error" class="image-slot" style="line-height: 50px;text-align: center">
                       无
                     </div>
                   </el-image>
                 </div>
                 <div class="preview" v-if="isPic" @click="isPic=false">
                   <div style="background-color: #FFFFFF;position: relative" >
                     <div style="z-index: 99;position: absolute;right: 0;font-size: 38px"><i class="el-icon-circle-close" style="color: #0b2043"></i> </div>
                     <el-image
                         style="width: 100%;"
                         :src="$getImageUrl(x[y.value])"
                         >
                       <div slot="error" class="image-slot" style="line-height: 50px;text-align: center">
                         无
                       </div>
                     </el-image>
                   </div>
                 </div>
               </div>
                <div v-else-if="y.type=='year'">{{$moment(x[y.value]).format('YYYY年')}}</div>
               <div v-else>
                 {{ x[y.value] }}
               </div>
              </td>
            </tr>
          </table>

          <div class="pager">
            <pager
                v-if="total>0"
                :page="page"
                :total="total"
                :page-count="size"
                @pagination="getList"
            />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  chengnuoList,
  chest, mhjSale,
  mountainIsland,
  nonprofitApplication,
  pharmacyServiceStation,
  regulatoryTask,
  saleOnline, storeDelivery, xfjSale, zdqyList, zichaList
} from "@/api/yaojian";
import pager from "./components/pager";
export default {
  name: "yunChild",
  components: { pager},
  data(){
    return {
      isPic:false,
      dialogShow:false,
      closeImg: require("@/assets/yun/dialog/c.png"),
      tableData:[],
      page:1,
      size:5,
      total:0,
      title:"",
      attr:[],
      pageType:"",
      today:[this.$moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),this.$moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")]
    }
  },
  methods:{
    open(t){
     this.pageType=t
      this.dialogShow=true
      this.getList()
    },
    getList(data){
    if(data){
      this.page=data.page
    }
      switch(this.pageType)
      {
        case "chengnuo":
          this.getChengnuo()
          break;
        case "zicha":
          this.getZicha()
          break;
        case "zdqy":
          this.getZdqy()
          break;
        case "mhj":
          this.getMhj()
          break;
        case "xfj":
          this.getXfj()
          break;
        case "wsyd":
          this.getWsyd()
          break;
        case "jgda":
          this.getJgda()
          break;
        case "msys":
          this.getMsys()
          break;
        case "ssjd":
          this.getSsjd()
          break;
        case "tsfw":
          this.getTsfw()
          break;
        case "znyx":
          this.getZnyx()
          break;
        case "wdds":
          this.getWdds()
          break;
        default:
          console.log("无动作")
      }
    },
    async getZdqy(){
      this.title="重点企业"
      let data={
        page:this.page,
        count:this.size,
        type:4
      }
      let rs=await zdqyList(data)
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px;width:50px"},
        {label:"企业名称",value:"storeName",style:"width:380px"},
        {label:"地址",value:"address",style:"width:700px"},
        {label:"联系人",value:"contact",style:""},

      ]
    },
    async getZicha(){
      this.title="自查情况"
      let data={
        page:this.page,
        count:this.size,
      }
      let rs=await zichaList(data)
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px;width:50px"},
        {label:"药店名称",value:"storeName",style:""},
        {label:"标题",value:"formTitle",style:""},
        {label:"创建日期",value:"createDate",style:""},

      ]
    },
    async getChengnuo(){
      this.title="承诺情况"
      let data={
        page:this.page,
        count:this.size,
      }
      let rs=await chengnuoList(data)
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px;width:50px"},
        {label:"药店名称",value:"storeName",style:""},
        {label:"标题",value:"commitmentTitle",style:""},
        {label:"签名",value:"sign",style:"",type:"pic"},
        {label:"创建日期",value:"createDate",style:""},

      ]
    },
    async getMhj(){
      this.title="含麻黄碱药品销售"
      let data={
        page:this.page,
        count:this.size,
        startTime:this.today[0],
        endTime:this.today[1],
      }
      let rs=await mhjSale(data)
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
        {label:"药品名称",value:"commonName"},
        {label:"药店名称",value:"storeName",style:"width:200px"},
        {label:"厂家",value:"company",style:"width:200px"},
        {label:"批号",value:"lot",style:""},
        {label:"单位",value:"unit",style:""},
        {label:"有效期",value:"validDate",style:""},
        {label:"患者姓名",value:"patientName",style:""},
        {label:"时间",value:"createDate",style:""},
      ]
    },
    async getXfj(){
      this.title="含兴奋剂药品销售"
      let data={
        page:this.page,
        count:this.size,
        startTime:this.today[0],
        endTime:this.today[1],
      }
      let rs=await xfjSale(data)
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
        {label:"药品名称",value:"commonName"},
        {label:"药店名称",value:"storeName",style:"width:200px"},
        {label:"厂家",value:"company",style:"width:200px"},
        {label:"批号",value:"lot",style:""},
        {label:"单位",value:"unit",style:""},
        {label:"有效期",value:"validDate",style:""},
        {label:"患者姓名",value:"patientName",style:""},
      ]
    },
   async getWsyd() {//网售药店
     this.title="网售药店"
     let rs=await saleOnline({type:0,tag:71,page:this.page,count:this.size})
     this.total=rs.data.total
     this.tableData=rs.data.list
     this.attr=[
         {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
         {label:"药店名称",value:"name"},
         // {label:"门头照",value:"name",type:'pic'},
         {label:"药店地址",value:"address",style:"width:450px"},
     ]
    },
    async getJgda() {//监管档案
      this.title="监管档案"
      let rs=await regulatoryTask({page:this.page,count:this.size})
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
        {label:"药店名称",value:"storeName"},
        {label:"监管问题",value:"remark"},
        {label:"监管所",value:"regulatoryName",style:""},
        {label:"联系人",value:"contract",style:""},
        {label:"时间",value:"createDate",style:""},
      ]
    },
    async getMsys() {//民生药事服务站点
      this.title="民生药事"
      let rs=await pharmacyServiceStation({page:this.page,count:this.size})
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
        {label:"药店名称",value:"storeName"},
        {label:"证件号",value:"licenseCode",style:"width:220px"},
        {label:"地址",value:"address",style:"width:450px"},
        {label:"时间",value:"createDate",style:"",type:"year"},
      ]
    },
    async getSsjd() {//上山进岛
      this.title="上山进岛"
      let rs=await mountainIsland({page:this.page,count:this.size})
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
        {label:"药店名称",value:"storeName"},
        // {label:"联系人",value:"contract",style:"width:220px"},
        {label:"地址",value:"address",style:"width:450px"},
      ]
    },
    async getZnyx() {//
      this.title="智能药箱"
      let data={page:this.page,count:this.size,startTime:this.$moment().format("YYYY-MM-DD 00:00:00"),endTime:this.$moment().endOf('year').format("YYYY-MM-DD 23:59:59")}
      let rs=await chest(data)
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
        {label:"药品名称",value:"commonName",style:""},
        {label:"患者手机",value:"patientPhone",type:"tel"},
      ]
    },
    async getTsfw() {//
      this.title="特色服务"
      let rs=await nonprofitApplication({page:this.page,count:this.size,status:20})
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
        {label:"药店名称",value:"storeName"},
        {label:"特色",value:"reason",style:""},
      ]
    },
    async getWdds() {//
      this.title="网订店送"
      let rs=await storeDelivery({page:this.page,count:this.size})
      this.total=rs.data.total
      this.tableData=rs.data.list
      this.attr=[
        {label:"序号",value:"index",style:"padding-left:20px",style2:"height: 120px"},
        {label:"药店名称",value:"storeName"},
        {label:"联系人",value:"contract",style:""},
        {label:"街道",value:"street",style:""},
        {label:"社区",value:"community",style:""},
        {label:"地址",value:"address",style:"width:400px"},
      ]
    },
    mbl(t) {
      if(t){
        if (t.length==11) {
          t = t.substring(0, 3) + "****" + t.substring(7);
        }
      }
      return t;
    },
    close(){
     this.page=1
      this.size=5
     this.total=0
      this.tableData=[]
    }
  }
}
</script>
<style lang="scss">
.preview{
  width: 800px;
  height: 800px;
  position: fixed;
  left: calc(50% - 400px);
  top:  calc(50% - 200px);
}
</style>
<style lang="less" scoped>
.txc{text-align: center}
.home {
  cursor: pointer;
  position: absolute;
  right: 62px;
  top: 29px;
  transform: translateY(-50%);
  span {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    line-height: 26px;
    margin: 0 30px 0 10px;
  }
  img {
    width: 23px;
    height: 19px;
  }
}
.wzname{
  color:#FFf
}
.wxf{
  color: #FFA500;
}
.shijian{
  z-index: 2;color: #03d3fb;
  font-size: 16px;
}
.absTu{
  position:absolute;z-index: 2;
}
.ControlSayContent{font-size: 30px;color: #fff;line-height: 150%;width: 600px;margin:0 auto;
  margin-top: 20px}
.score{font-size: 30px}
.levelA{
  text-shadow: 2px 2px 0px #1483ff;
  color: #0635E6!important;
  font-size: 50px!important;
}
.levelB{
  text-shadow: 2px 2px 0px #dcffd8;
  color: #22F906!important;
  font-size: 50px!important;
}
.levelC{
  text-shadow: 2px 2px 0px #fff6a5;
  color: #FEE610!important;
  font-size: 50px!important;
}
.levelD{
  text-shadow: 2px 2px 0px #ff8d8d;
  color: #F90606!important;
  font-size: 50px!important;
}
.yuan100{
  border-radius: 50%;border: 2px solid #E82A02;
}
.khpj1{
  img{
    margin-left: 5px;
  }
  background-image: url("../assets/yun/dialog/khpj1.png");
  height: 50px;
  width: 800px;
  line-height: 50px;color: #00D3F9;
  position: absolute;
  bottom:74px;
  font-size: 24px;
  color: #00d8ff;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}
.khpj2{
  img{
    margin-left: 5px;
  }
  background-image: url("../assets/yun/dialog/khpj2.png");
  width: 850px;
  height:50px;
  line-height: 50px;color: #00D3F9;
  position: absolute;
  bottom:74px;
  font-size: 24px;
  color: #ff0000;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.green{color: #1DCE3A}
.dandian{
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  .item{
    text-align: center;
    background-image: url("../assets/yun/dialog/bmd.png");
    width: 735px;height: 405px;
    .item1{margin-top: 93px;

      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #EEEFEF;

    }
    .item2{margin-top: 120px;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #00D7FE;
    }
  }
}
#inquire {
  margin-top: 40px;
  width: 1527px;
  height: 60px;
  border: 1px solid #0277fa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .inquire-title {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    line-height: 25px;
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0277fa;
    border-right: 1px solid #0277fa;
  }
  .active {
    color: #fff;
    background: #0094fa;
  }
}
.inquire-store {
  margin-top: 40px;
}
.shxf {
  width: 120px;
  height: 40px;
  background: #0277fa;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
}
.jrfxcz {
  display: flex;
  width: 1545px;
  height: 704px;
  border: 1px solid #0277fa;
  .itemLeft {
    .active {
      background-color: #062753;
    }
    width: 330px;
    background-color: rgba(17, 17, 17, 0.43);
    .item {
      display: flex;
      height: 120px;
      padding-left: 20px;
    }
    .item1 {
      line-height: 120px;
      width: 215px;
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #03d7ff;
    }
    .item2 {
      line-height: 120px;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      span {
        color: #03d7ff;
      }
    }
  }
  .itemRight {
    width: 1215px;
    height: 704px;
    background: rgba(0, 111, 236, 0.2);
  }
}
.picContent {
  .item {
    border-bottom: 1px dashed #00d7fe;
    display: flex;
    align-items: center;
    height: 200px;
    .item1 {
      width: 250px;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #00d7fe;
      line-height: 35px;
    }
    .item2 {
      flex: 1;
      overflow: auto;
      display: flex;
      .item2-1 {
        margin-right: 30px;
      }
    }
    .item3 {
      width: 100px;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #22f906;
      line-height: 35px;
    }
  }
}
.drug-control-header-back {
  cursor: pointer;
  position: absolute;
  left: 46px;
  top: 60%;
  transform: translateY(-50%);
  span {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00d7fe;
    line-height: 26px;
    margin: 0 30px 0 10px;
  }
  img {
    width: 23px;
    height: 19px;
  }
}
</style>
<style scoped>
 .level_vc{
   display: flex;
   justify-content: center;
   align-items: center;
 }
.tagBtn{padding: 5px 20px;border-radius: 30px;text-align: center}
.lctBlock {
  padding: 25px 35px;
}
.lctBlock .item {
  padding: 10px 20px;
  width: 260px;
  height: 161px;
  background: rgba(0, 111, 236, 0.1);
  border-radius: 4px;
}
.lctBlock .item1 {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #03d7ff;
  line-height: 35px;
}
.lctBlock .item2 {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #03d7ff;
  line-height: 35px;
}
.lctBlock .item3 {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 220px;
  height: 1px;
  background: #03d7ff;
}
.lctBlock .item4 {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  line-height: 25px;
}
.lctBlock .active {
  width: 260px;
  height: 161px;
  background: #bd8101;
  border: 1px solid #ffd93b;
  border-radius: 4px;
}
.lctBlock .active .item1 {
  color: #ffffff;
}
.lctBlock .active .item2 {
  color: #ffffff;
}
.red {
  color: red;
}
.jianfen {
  font-size: 30px;
  font-family: Arial;
}
.lctBlock {
  margin-top: 24px;
  width: 1545px;
  height: 441px;
  background: rgba(0, 111, 236, 0.1);
}
.lctTitle {
  display: flex;
  justify-content: space-between;
}
.lctTitle .item1 {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #03d7ff;
}
.lctTitle .item2 {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}
.lctTitle1 {
  background-image: url("../assets/yun/dialog/lct4.png");
  width: 515px;
  height: 140px;
  text-align: center;
  line-height: 140px;
}
.lctTitle2 {
  background-image: url("../assets/yun/dialog/lct5.png");
  width: 1010px;
  height: 140px;
  text-align: center;
  line-height: 140px;
}
.txt-c {
  text-align: center;
}
.jkm >>> .el-tag {
  font-size: 20px;
}
.dialogBlock .active {
  border: 1px solid #fff;
  background: #ee6700 !important;
  /*  background: url("../assets/yun/dialog/1.png") !important;
    background-size: 100% 100% !important;*/
}
.dialogBlock .active .item1 {
  color: #fff !important;
}
.dialogBlock {
  display: flex;
  width: 100%;
  justify-content: left;
}
.dialogBlock .item {
  margin-right:20px;
  width: 340px;
  padding: 10px;
  display: flex;
  height: 88px;
  line-height: 88px;
  background: #043f91;
}
.dialogBlock .item1 {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-left: 40px;
  font-size: 30px;
  color: #01c0e5;
}
.dialogBlock .item2 {
  font-size: 36px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ceec02;
}
.dialogBlock .item3 {
  font-size: 30px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ec7002;
  margin-left: 20px;
}
.listTsrq {
  justify-content: space-between;
}
.listTsrq .item {
  width: 45%;
}
.ThreeGradeTop .item2-1 {
  font-size: 36px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ec7002;
  line-height: 40px;
}
.ThreeGradeTop .item2-2 {
  line-height: 40px;
}
.ThreeGradeTop {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.ThreeGradeTop .item {
  display: flex;
}
.ThreeGradeTop .item1 {
  font-size: 26px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #01c0e5;
  line-height: 80px;
  margin-right: 10px;
}
.ThreeGradeTop .item2 {
  font-size: 36px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ceec02;
}
.singleField {
  padding-left: 20px;
}
.pici {
  padding: 0px 3px;
  height: 30px;
  margin-right: 5px;
  line-height: 30px;
  text-align: center;
  background: rgba(2, 119, 250, 0.2);
  border: 1px solid #0277fa;
  border-radius: 4px;
}
.overtwo {
  width: 200px;

  word-break: break-all;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 2; /* 这里是超出几行省略 */

  overflow: hidden;
}
.overthree {
  width: 200px;

  word-break: break-all;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 3; /* 这里是超出几行省略 */

  overflow: hidden;
}
.shuzi {
  white-space: nowrap;
  color: #40a0ff;
  font-size: 30px;
  text-align: center;
}
.time {
  width: 109px;
}
.nowrap {
  white-space: nowrap;
}
/*滚动条----------------------------开始*/
.scrbar::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}
.scrbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0 0 0 0.2);
  background: #13c2f8;
}
.scrbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0 0 0 0.2);
  border-radius: 10px;
  background: #010324;
}
/*滚动条----------------------------------结束*/
.tymBox {
  justify-content: flex-start;
  flex-wrap: wrap;
  color: #00b7ff;
  overflow: auto;
  height: 210px;
}
.tym {
  width: 25%;
  line-height: 200%;
  font-size: 18px;
}
.btn {
  cursor: pointer;
  border: 1px solid #00b7ff;
  border-radius: 5px;
  padding: 5px 15px;
}
.chakan {
  height: 160px;
  padding-top: 25px;
}
.listData2 > td {
  color: #fff;
  font-size: 20px;
}
.viewContent tr td {
  line-height: 200%;
}
.viewContent {
  top: 90px;
  left: 30px;
  border: 1px solid #026d97;
  width: 1439px;
  padding: 0px 1px;
  position: absolute;
}
.tdBtn {
  height: 136px;
}
.tdContent {
}
.pager-post{
  position: absolute;
  bottom: 80px;
  left: 50%;
}
.zgbtn1 {
  background: url("../assets/smart/home/yzg.png");
  background-size: 100% 100%;
  width: 235px;
  height: 68px;
  line-height: 68px;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.ztbtn2 {
  background: url("../assets/smart/home/wzg.png");
  background-size: 100% 100%;
  width: 235px;
  height: 68px;
  line-height: 68px;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.yzg {
  background: url("../assets/smart/home/yzg.png");
  background-size: 100% 100%;
  width: 235px;
  height: 68px;
  line-height: 68px;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.wzg {
  background: url("../assets/smart/home/wzg.png");
  background-size: 100% 100%;
  width: 235px;
  height: 68px;
  line-height: 68px;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.pager {
  align-items: center;
  color: #fff;
  justify-content: center;
}
.page {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: rgba(1, 4, 41, 0.8);
  border-radius: 3px;
  border: 1px solid #1ac9ff;
  color: #16a6d9;
  text-align: center;
}
.zzzg {
  justify-content: space-between;
  padding: 0px 20px;
  padding-top: 20px;
}
.zzzg .item {
  width: 150px;
  cursor: pointer;
}
.zzzg .item1 {
  font-size: 24px;
  background: url("../assets/smart/home/zzzg.png");
  background-size: 100% 100%;
  width: 150px;
  height: 56px;
  line-height: 56px;
  text-align: center;
}
.zzzg .item2 {
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.tdView {
  height: 230px;
}
.btnBack {
  position: absolute;
  right: 20px;
  /*background: url("../assets/smart/home/back.png");*/
  background-size: 100% 100%;
  font-size: 20px;
  font-weight: normal;
  padding: 5px 10px;
}
.btnBack img {
  width: 50px;
  height: 50px;
}
.btnDetail {
  background: url("../assets/smart/home/btn_view.png");
  background-size: 100% 100%;
  width: 108px;
  height: 30px;
  line-height: 30px;
  color: #00b7ff;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}
.btnDetail2 {
  background: url("../assets/smart/home/btn_view2.png");
  background-size: 100% 100%;
  width: 108px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}
.boxcontent {
  border: 2px solid rgba(74, 144, 255, 0.5);
  position: absolute;
  width: 1536px;
  height: 175px;
  margin-top: 46px;
}
.overflow {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.huizong {
  display: flex;
  margin-bottom: 10px;
}
.flex_vc {
  display: flex;
  align-items: center;
}
.yuan {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  display: block;
  /* 宽度和高度需要相等 */
}
.huizong .btnHui {
  margin-right: 10px;
  color: #00b7ff;
  font-size: 20px;
  background-image: url("../assets/smart/home/huibtn.png");
  background-size: 100% 100%;
  width: 151px;
  height: 52px;
}
.pager {
  position: absolute;
  bottom: 30px;
  width: 100%;
}
.tag >>> .el-tag {
  font-size: 20px;
}
.dazi .listData > td {
  font-size: 20px;
}
.tanContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 300px;
  margin: 0 auto;
}
.tanContent .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 229px;
  height: 194px;
  background-image: url("../assets/smart/home/dialog3.png");
  background-size: 100% 100%;
}
.tanContent .item1 {
  font-family: Arial;
  font-size: 30px;
  color: #febe13;
  line-height: 150%;
}
.tanContent .item2 {
  font-size: 16px;
  color: #fff;
  line-height: 200%;
}
.flex {
  display: flex;
}
.btnSearch {
  background-repeat: no-repeat;
  background-position: center;
  color: #01b7ff;
  cursor: pointer;
  user-select: none;
  font-weight: bolder;
  text-align: center;
  font-size: 16px;
}
.saleSearch {
  margin-bottom: 20px;
}
.saleSearch >>> .el-select__tags {
  background-color: transparent;
  flex-wrap: nowrap;
  overflow: hidden;
}
.saleSearch >>> .el-input__inner {
  background-color: transparent;
  font-size: 20px;
  width: 220px;
  color: #00b7ff;
  border: 1px solid #00b7ff;
}
.saleSearch .item {
  margin-right: 15px;
}
.saleSearch .item >>> .el-range-input {
  background: #07152d;
  color: #fff;
}
.saleSearch .item >>> .el-range-separator {
  color: #fff;
}
.saleSearch .item >>> .el-date-editor--daterange {
  width: 252px;
}
.saleSearch .label {
  padding-right: 5px;
  color: #00b7ff;
  line-height: 44px;
  font-size: 20px;
}

/*药店搜索           start*/
.storeSearch >>> .el-input__inner {
  background-color: transparent;
  font-size: 20px;
  color: #00b7ff;
  border: 1px solid #00b7ff;
}
.storeSearch .label {
  padding-right: 5px;
  color: #00b7ff;
  line-height: 44px;
  font-size: 20px;
}
/*药店搜索           end*/
.biao {
  padding-top: 10px;
}
.server{
  height: 665px;

}
.server-content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: space-between;
}
.server-commont{
  box-sizing: border-box;
  width: 500px;
  height: 209px;
  margin-top: 24px ;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: rgba(0, 111, 236, 0.1);
  border: 1px solid #03D7FF;
}

.server-commont-title{
  text-align: center;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #01C0E5;
  line-height: 19px;
}
.server-time{
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-top: 25px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 19px;
  margin-left: 20px
}
.server-year{
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-top: 19px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 19px;
}
.jxc td {
  padding-left: 10px;
  height: 120px;
}
.hand {
  cursor: pointer;
}
.listData .name {
  line-height: 80px;
}
.dabiaozi > td {
  padding: 20px 10px;
  line-height: 150%;
}
.tx-c {
  text-align: center;
}
.td-c td {
  text-align: center;
}
.tdSale > td {
  padding-left: 7px;
  padding-right: 7px;
  line-height: 140%;
}
.listData > td {
  height: 100px;
  color: #fff;
  vertical-align: middle;
  font-size: 20px;
  padding: 0 10px;
}
.listData:nth-child(odd) {
  background-color: rgba(2, 119, 250, 0.1);
}
.listtable {
  width: 100%;
  border-collapse: collapse;
}
.listTitle > td {
  padding: 0 7px;
  white-space: nowrap;
}
.listTitle {
  color: #00d8ff;
  background: rgba(2, 119, 250, 0.1);
  height: 46px;
  font-size: 18px;
  line-height: 46px;
}
.biao2 td {
  padding: 0px 7px;
  line-height: 30px;
}
.listTitle2 td {
  padding: 0px 7px;
}
.listTitle2 {
  color: #fff;
  background: rgba(2, 119, 250, 0.5);
  line-height: 26px;
  font-size: 18px;
}
.listData {
  color: #ffffff;
  cursor: pointer;
}

.btnBack {
  position: absolute;
  right: 20px;
  background-size: 100% 100%;
  font-size: 20px;
  font-weight: normal;
  padding: 5px 10px;
}
.btnBack2 {
  position: absolute;
  right: 20px;
  /*background: url("../assets/smart/home/dialog2.png");*/
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 20px;
  font-weight: normal;
  padding: 5px 10px 5px 20px;
}
.tanTitle {
  position: relative;
  /*background: url("../assets/smart/home/titbg.png");*/
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: 4px;
  margin-bottom: 20px;
}
.tanTitle2 {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  color: #00b7ff;
  font-size: 26px;
  font-weight: bolder;
  letter-spacing: 7px;
  margin-bottom: 20px;
  padding-top: 3px;
}
.tanWin {
  background: url("../assets/yun/dialog/bg.png");
  background-size: 100% 100%;
  height: 835px;
  padding: 10px 30px;
  position: relative;
}
.tanWinTishi {
  background: url("../assets/yun/dialog/tishi.png");
  background-size: 100% 100%;
  height: 332px;
  padding: 10px 30px;
  position: relative;
}
.online{
  display: flex;
  flex-direction: column;
}
.online-table{
  width: 1145px;
  margin-left: 61px;
}
.pagecomont{
  position: absolute;
  bottom: 30px;
  width: 100%;
  left: 150px;
}
.online-commont{
  display: flex;
}
.chart3d-2{
  color: #00d7fe !important;
  font-size: 24px;
  font-weight: 700;
  top: 392px;
  left: 121px;
  color: white;
  position: absolute;
}
.legend3d_1{
  position: absolute;
  bottom: 50%;
  left: 48px;
  top: 442px;
  font-size: 22px;
  line-height: 150%;
}

.abs{
  position: absolute;
}
#chart3d_1 {
  width: 340px;
  height: 340px;
}
.tanWin2 {
  background: url("../assets/yun/dialog/bg2.png");
  background-size: 100% 100%;
  height: 332px;
  padding: 8px 30px;
}

.pageTitle {
  font-weight: bolder;
  letter-spacing: 10px;
  text-align: center;
}
/*el-dialog样式------------------------------------start*/
.dialog >>> .el-dialog {
  background-color: transparent;
}
.dialog >>> .el-dialog__header {
  display: none;
}
.dialog >>> .el-dialog__wrapper .el-dialog .el-dialog__body {
  padding: 0;
}
/*el-dialog样式------------------------------------end*/
/*el-table样式------------------------------------start*/
.biao >>> .el-table,
.biao >>> .el-table__expanded-cell {
  background-color: transparent !important;
}

.biao >>> .el-table th,
>>> .el-table tr {
  border: 0 !important;
  background-color: transparent !important;
}
/*鼠标移上去颜色*/
.biao >>> .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(0, 85, 255, 0.29);
}

/*行分隔线*/
.biao >>> .el-table__row > td {
  color: #f5fdff;
  border-bottom: 1px solid #004f68;
}

/* 去掉最下面的那一条线 */
.biao >>> .el-table::before {
  height: 0px;
}

/*el-table样式------------------------------------end*/
</style>