<template>
<div>
<div ref="city" style="width: 666px;height: 600px"></div>
  <div class="num">
    <div class="city pj" style="top:120px;left:287px">{{store['330726']}}</div>
    <div class="city lx" style="top:231px;left:168px">{{store['330781']}}</div>
    <div class="city wc" style="top:363px;left:159px">{{store['330702']}}</div>
    <div class="city jd" style="top:287px;left:249px">{{store['330703']}}</div>
    <div class="city yw" style="top:221px;left:336px">{{store['330782']}}</div>
    <div class="city wy" style="top:451px;left:219px">{{store['330723']}}</div>
    <div class="city yk" style="top:385px;left:361px">{{store['330784']}}</div>
    <div class="city dy" style="top:248px;left:458px">{{store['330783']}}</div>
    <div class="city pa" style="top:322px;left:509px">{{store['330727']}}</div>
  </div>
</div>
</template>

<script>
import * as echarts from "echarts";
import geoJson from "@/assets/json/330700_full.json"
import {storeCount} from "@/api/yaojian";
export default {
  name: "cityMap",
  props: {
    dialogData: {
      type: Object,
      default: () => {}
    },
  },
  data(){
    return {
      store:{}
    }
  },
  mounted() {
  this.setMap()
    },
  created() {
  this.getStore()
    },
  methods:{
   async getStore(){
      // let rs=await storeCount()
     this.store= this.dialogData // rs.data
    },
    changeCity(name){
      let arr=this.$city
      let id=arr.find(x=>x.name==name).code
      this.$store.commit('SET_cityId',id)
      this.$emit("changeCity",{id:id})
    },
    setMap(){
      const that=this
      var hzmap = "/assets/json/330100.json";
      var mapname = hzmap
      var mapDate = [{
        name: '婺城区',
        value: [120.185, 30.274, 29999]
      },
      ];
      /**
       此版本通过设置geoindex && seriesIndex: [1] 属性来实现geo和map共存，来达到hover散点和区域显示tooltip的效果

       默认情况下，map series 会自己生成内部专用的 geo 组件。但是也可以用这个 geoIndex 指定一个 geo 组件。这样的话，map 和 其他 series（例如散点图）就可以共享一个 geo 组件了。并且，geo 组件的颜色也可以被这个 map series 控制，从而用 visualMap 来更改。
       当设定了 geoIndex 后，series-map.map 属性，以及 series-map.itemStyle 等样式配置不再起作用，而是采用 geo 中的相应属性。

       http://echarts.baidu.com/option.html#series-map.geoIndex

       并且加了pin气泡图标以示数值大小
       */
      const myChart = echarts.init(this.$refs.city);
      myChart.showLoading();
      let index = -1;

      myChart.on('mousemove', function(e) {
       // clearInterval(timer);
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0
        });
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: e.dataIndex
        });
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: e.dataIndex
        });
      }); //---------------------------------------------鼠标移入静止播放
      myChart.on('mouseout', function(e) {
       // clearInterval(timer);
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: e.dataIndex
        }); //鼠标移出后先把上次的高亮取消
      });
      myChart.on('click', function(e) {
        console.log(e,"eee")
        that.changeCity(e.name)
        mapDate = []
        mapInit()
      })
      setTimeout(function() {
        mapInit()
      }, 1000);
      var mapInit = () => {
        console.log('初始化地图', mapname)
          echarts.registerMap('hanzhou', geoJson);
          myChart.hideLoading();
        let  option = {
          legend:{
            show:false
          },
            //backgroundColor: '#020933',
            geo: {
              map: 'hanzhou',
              aspectScale: 0.75, //长宽比
              zoom: 1.1,
              roam: false,
              itemStyle: {
                normal: {
                  areaColor: '#013C62',//无效参数
                  shadowColor: '#02c2cf',//阴影颜色
                  shadowOffsetX: 5,
                  shadowOffsetY: 7
                },
                emphasis: {
                  areaColor: '#2AB8FF',
                  borderWidth: 0,
                  color: 'green',
                  label: {
                    show: false
                  }
                }
              }
            },
            series: [{
              type: 'map',
              roam: true,
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    color: '#fff'
                  }
                },
                emphasis: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              },

              itemStyle: {
                normal: {
                  borderColor: '#2ab8ff',
                  borderWidth: 1.5,
                  areaColor: '#01457a'
                },
                emphasis: {
                  areaColor: '#2AB8FF',
                  borderWidth: 0,
                  color: 'green'
                }
              },
              zoom: 1.1,
              roam: false,
              map: 'hanzhou' //使用
              // data: this.difficultData //热力图数据   不同区域 不同的底色
            },
              {
                type: 'effectScatter',
                coordinateSystem: 'geo',
                showEffectOn: 'render',
                rippleEffect: {
                  period: 15,
                  scale: 4,
                  brushType: 'fill'
                },
                hoverAnimation: true,
                itemStyle: {
                  normal: {
                    color: '#ffff',
                    shadowBlur: 10,
                    shadowColor: '#333'
                  }
                },
                data: mapDate
              }

            ]
          };
          myChart.setOption(option);

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.num{height:600px;width:100%;margin:0;position: relative;z-index: 999;margin-top: -600px;
  user-select:none;
  pointer-events:none;
  color: #FBD502;
}
.city{position: absolute;}
</style>